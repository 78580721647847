@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
ul.helper-text {
  display: none;
  margin-top: 6px;
  font-size: 12px;
  line-height: 22px;
  color: red; }
  ul.helper-text li.valid {
    color: #32CD32; }

ul.helper-text.show {
  display: block; }

.login {
  width: 100%;
  height: 100vh; }
  @media all and (max-width: 959px) {
    .login .grid {
      display: none; } }
  .login .h1 {
    position: absolute;
    top: 90px;
    left: 50px;
    color: white;
    line-height: 54px;
    width: 45%; }
    @media all and (max-width: 1920px) {
      .login .h1 {
        font-size: 36px; } }
    @media all and (max-width: 1280px) {
      .login .h1 {
        font-size: 24px; } }
    @media all and (max-width: 959px) {
      .login .h1 {
        display: none; } }
  .login .logo {
    position: absolute;
    top: 50px;
    left: 50px; }
    @media all and (max-width: 1920px) {
      .login .logo {
        height: 49px; } }
    @media all and (max-width: 1280px) {
      .login .logo {
        height: 33px; } }
    @media all and (max-width: 959px) {
      .login .logo {
        display: none; } }
  .login .login-container {
    background-image: url(https://dev.settime.io/static/media/bg-1920.13539e79.jpg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%; }
    @media all and (max-width: 1920px) {
      .login .login-container {
        background-image: url(https://dev.settime.io/static/media/bg-1920.13539e79.jpg); } }
    @media all and (max-width: 1440px) {
      .login .login-container {
        background-image: url(https://dev.settime.io/static/media/bg-1440.44b3e0d6.jpg); } }
    @media all and (max-width: 1280px) {
      .login .login-container {
        background-image: url(https://dev.settime.io/static/media/bg-1280.5e58c0c6.jpg); } }
    @media all and (max-width: 1024px) {
      .login .login-container {
        background-image: url(https://dev.settime.io/static/media/bg-1024.9df7c3b2.jpg); } }
    @media all and (max-width: 959px) {
      .login .login-container {
        display: none; } }
  .login .header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px; }
    .login .header-box .logo-box {
      display: none; }
      @media all and (max-width: 959px) {
        .login .header-box .logo-box {
          display: block; } }
    .login .header-box .content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
      .login .header-box .content-header .back-text {
        color: #393939;
        font: normal 700 14px/20px Nunito Sans, sans-serif; }
  .login .languages-dropdown {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%; }
    .login .languages-dropdown .MuiSelect-icon {
      display: none; }
    .login .languages-dropdown label + .MuiInput-formControl {
      margin-top: 0; }
  .login .form-container {
    margin-top: 60px; }
    @media all and (max-width: 959px) {
      .login .form-container {
        margin-top: 0; } }
    .login .form-container .form-box {
      background: #FFFFFF;
      box-shadow: 0 2px 18px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin: 0 auto;
      max-width: 400px;
      width: 100%; }
      .login .form-container .form-box .title {
        font: normal 600 24px/33px "Nunito Sans", sans-serif;
        color: #393939;
        margin-bottom: 10px;
        margin-top: 0;
        text-align: center; }
      .login .form-container .form-box .sub-title {
        font: normal 500 14px/19px "Nunito Sans", sans-serif;
        color: #707070;
        margin-bottom: 43px;
        margin-top: 0;
        text-align: center; }
        .login .form-container .form-box .sub-title .verification-code-time {
          font: normal 600 14px/19px "Nunito Sans", sans-serif;
          color: #000000; }
      .login .form-container .form-box .resend-code-row {
        margin: 40px auto 59px auto; }
        .login .form-container .form-box .resend-code-row p {
          font: normal 500 12px/16px Nunito Sans, sans-serfif;
          color: #393939;
          margin: 0;
          text-align: center; }
      .login .form-container .form-box .forgot-password {
        display: flex;
        justify-content: end;
        margin-top: 15px;
        margin-bottom: 30px; }
        .login .form-container .form-box .forgot-password a span {
          font: normal 600 14px/19px "Nunito Sans", sans-serif;
          color: #393939; }
      .login .form-container .form-box .sign-in-btn {
        text-align: center;
        border-radius: 4px; }
        .login .form-container .form-box .sign-in-btn button {
          height: 36px;
          font: normal 600 14px/19px "Nunito Sans", sans-serif; }
    .login .form-container .forgot-pass-email-input {
      margin-bottom: 110px; }
    .login .form-container .password-detail {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 10px;
      gap: 10px;
      margin-bottom: 29px;
      margin-top: 6px; }
      .login .form-container .password-detail .error-text {
        font: normal 500 12px/16px Nunito Sans, sans-serif;
        text-align: center;
        color: #F12B2C;
        margin: 0; }
      .login .form-container .password-detail .text {
        font: normal 500 12px/16px Nunito Sans, sans-serif;
        text-align: center;
        color: #707070;
        margin: 0; }
  .login .app-box {
    margin: 50px auto;
    max-width: 400px;
    text-align: center; }
    .login .app-box .title {
      font: normal 600 24px/33px "Nunito Sans", sans-serif;
      color: #393939;
      margin-top: 0;
      margin-bottom: 6px; }
    .login .app-box .sub-text {
      font: normal 500 14px/19px "Nunito Sans", sans-serif;
      color: #707070;
      margin-top: 0;
      margin-bottom: 3px; }
    .login .app-box .store-links {
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 20px;
      gap: 20px;
      margin-top: 37px; }

.verify-email {
  color: #0282DA; }

.email-check {
  margin: 30px auto !important; }
  .email-check img {
    display: block;
    width: 100%; }

.resend-email a {
  text-decoration: underline;
  padding: 0 4px; }

.img-box-personal {
  background-image: url(https://dev.settime.io/static/media/personal-type.8b2cafa5.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 150px;
  width: 150px;
  margin: 0 auto; }
  .img-box-personal .read-more:hover {
    text-decoration: underline !important; }

.img-box-business {
  background-image: url(https://dev.settime.io/static/media/business-type.10d50e0c.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 150px;
  width: 150px;
  margin: 0 auto; }
  .img-box-business .read-more:hover {
    text-decoration: underline !important; }

.img-box {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .img-box .img-container {
    max-width: 100%;
    height: 100px;
    margin-bottom: 15px; }
    .img-box .img-container img {
      height: 100%;
      width: 100%;
      object-fit: contain; }

.read-more p {
  color: #A4A4A4;
  width: 166px;
  font-size: 12px;
  margin-top: 10px; }

.card-account-type {
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
  background-color: #FFFFFF;
  padding: 20px 0;
  border: 2px solid transparent;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }
  .card-account-type:hover {
    box-shadow: rgba(91, 95, 99, 0.25) 0 25px 50px -12px !important; }

@media all and (max-width: 715px) {
  .type-account {
    flex-direction: column; } }

#dropzone .dropzone-container {
  min-height: 120px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: sans-serif;
  padding: 0; }

#dropzone .dropzone-container > p {
  font-size: 1rem; }

#dropzone .dropzone-container > em {
  font-size: .8rem; }

#dropzone .dropzone {
  min-width: 150px;
  max-width: 150px;
  height: 150px;
  background-image: url(https://dev.settime.io/static/media/add-img.4dfd964b.png);
  background-color: #e5e5e5;
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 3em; }
  #dropzone .dropzone:focus, #dropzone .dropzone:hover {
    border-color: #2196f3;
    cursor: pointer; }
  #dropzone .dropzone.disabled {
    opacity: 0.6; }

.show-logo-content {
  position: relative; }
  .show-logo-content .image-mask {
    display: flex;
    justify-content: center;
    align-items: center; }
  .show-logo-content .image-mask {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: calc(100% - 15px);
    height: 100%;
    background: rgba(0, 0, 0, 0.5); }

.card-plus-box {
  margin: 15px;
  border: 1px solid #ececec;
  height: 295px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAABHNCSVQICAgIfAhkiAAABEBJREFUeF7t2zGOU1sURFF6pgQkjAgCkGCi+OvnLeFT2pLVYjl+Vfe+8nLot08+FnjhAm8vPNvRFvgEIAQvXQDAl87vcAAZeOkCAL50focDyMBLFwDwpfM7HEAGXroAgC+d3+EAvmPg8Xi8ff/5+0/J4+uXz7Z+Z1CjAFj+zs5dAAJ4RlMGAASw9HTuAhDAM5oyACCApadzF4AAntGUAQABLD2duwAE8IymDAAIYOnp3AUggGc0ZQBAAEtP5y4AATyjKQMAAlh6OncBCOAZTRkAEMDS07kLQADPaMoAgACWns5dAAJ4RlMGAASw9HTuAhDAM5oyACCApadzF4AAntGUAQABLD2duwAE8IymDAAIYOnp3AUggGc0ZQBAAEtP5y4AATyjKQMAAlh6OncBCOAZTRkAEMDS07kLQADPaMoAgACWns5dAAJ4RlMGAASw9HTuAhDAM5oyACCApadzF4AAntGUAQABLD2duwAE8IymDAAIYOnp3AUggGc0ZQBAAEtP5y4AATyjKQMAAlh6OncBCOAZTRkAEMDS07kLQADPaMoAgACWns5dAAJ4RlMGAASw9HTuAhDAM5oyACCApadzF4AAntGUAQABLD2duwAE8IymDAAIYOnp3AUggGc0ZQBAAEtP566nAX778etxbhf4Zxf4+uXzU7aeeuj/FQH8Zy1NLw7gNJtQtQCA1ZJ6pgUAnGYTqhYAsFpSz7QAgNNsQtUCAFZL6pkWAHCaTahaAMBqST3TAgBOswlVCwBYLalnWgDAaTahagEAqyX1TAsAOM0mVC0AYLWknmkBAKfZhKoFAKyW1DMtAOA0m1C1AIDVknqmBXKA0y0+aOjxeLx9//n7T3n9Z7+Q8syP0PX0f0I+wstUdwSwWvLvPQC+sxGAf4dTPQEggJWlqQdAACc4VQhAACtLUw+AAE5wqhCAAFaWph4AAZzgVCEAAawsTT0AAjjBqUIAAlhZmnoABHCCU4UABLCyNPUACOAEpwoBCGBlaeoBEMAJThUCEMDK0tQDIIATnCoEIICVpakHQAAnOFUIQAArS1MPgABOcKoQgABWlqYeAAGc4FQhAAGsLE09AAI4walCAAJYWZp6AARwglOFAASwsjT1AAjgBKcKAQhgZWnqARDACU4VAhDAytLUAyCAE5wqBCCAlaWpB0AAJzhVCEAAK0tTD4AATnCqEIAAVpamHgABnOBUIQABrCxNPQACOMGpQgACWFmaegAEcIJThQAEsLI09QAI4ASnCgEIYGVp6gEQwAlOFQIQwMrS1AMggBOcKgQggJWlqQdAACc4VQhAACtLUw+AAE5wqhCAAFaWph4AAZzgVCEAAawsTT0AAjjBqUIAAlhZmnoAnGYTqhYAsFpSz7QAgNNsQtUCAFZL6pkWAHCaTahaAMBqST3TAgBOswlVCwBYLalnWgDAaTahagEAqyX1TAv8B1DdmM7hS9zKAAAAAElFTkSuQmCC);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5em;
  z-index: 100; }
  @media all and (max-width: 768px) {
    .card-plus-box {
      display: none; } }
  .card-plus-box:hover {
    box-shadow: rgba(91, 95, 99, 0.25) 0 25px 50px -12px !important;
    cursor: pointer; }

.staff-box:hover {
  cursor: pointer; }

.staff-fields,
.customer-fields,
.appointments-fields {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .staff-fields .search-staff,
  .staff-fields .search-customer,
  .customer-fields .search-staff,
  .customer-fields .search-customer,
  .appointments-fields .search-staff,
  .appointments-fields .search-customer {
    margin-right: 20px; }
    .staff-fields .search-staff svg,
    .staff-fields .search-customer svg,
    .customer-fields .search-staff svg,
    .customer-fields .search-customer svg,
    .appointments-fields .search-staff svg,
    .appointments-fields .search-customer svg {
      color: #8c949d; }

.card-box-root {
  height: 295px; }
  .card-box-root .MuiBox-root:hover {
    cursor: pointer; }

.customized-dialog-content .MuiTab-fullWidth {
  flex-basis: auto; }

.customized-dialog-content #staff-tab-panel .MuiGrid-container {
  min-height: 230px;
  position: relative;
  padding: 5px;
  width: 100%;
  margin: 0; }

.customized-dialog-content .staff-tab-card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 250px;
  padding: 10px 0; }
  .customized-dialog-content .staff-tab-card h6 {
    margin-bottom: 10px; }
  .customized-dialog-content .staff-tab-card h5 {
    margin: 10px 5px 10px 0; }
  .customized-dialog-content .staff-tab-card p {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 0; }
  .customized-dialog-content .staff-tab-card .history-container {
    box-shadow: none; }
    .customized-dialog-content .staff-tab-card .history-container th {
      text-align: center;
      background-color: #FFFFFF; }
    .customized-dialog-content .staff-tab-card .history-container td {
      font-size: 14px;
      text-align: center; }
    .customized-dialog-content .staff-tab-card .history-container .name {
      display: block;
      white-space: nowrap;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis; }
  .customized-dialog-content .staff-tab-card .services-container {
    box-shadow: none; }
    .customized-dialog-content .staff-tab-card .services-container th {
      background-color: #FFFFFF; }
    .customized-dialog-content .staff-tab-card .services-container tbody td {
      font-size: 14px; }
  .customized-dialog-content .staff-tab-card .card-box-avatar {
    width: 140px;
    height: 140px;
    font-size: 3em; }
  .customized-dialog-content .staff-tab-card .staff-details-item {
    margin-top: 10px;
    font-size: 13px;
    font-weight: normal; }
  .customized-dialog-content .staff-tab-card .staff-details-info {
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize; }
    .customized-dialog-content .staff-tab-card .staff-details-info li {
      list-style: none; }

.customized-dialog-content .staff-tab-card.MuiPaper-elevation1 {
  box-shadow: none; }

.card-header-service.accordion-category .MuiIconButton-edgeEnd {
  margin-right: 0; }

.appointment-status {
  text-align: center;
  padding: 4px 0;
  color: white;
  border-radius: 3px; }
  .appointment-status.s_2 {
    background-color: #F3B175; }
  .appointment-status.s_4 {
    background-color: #0282DA; }
  .appointment-status.s_3 {
    background-color: #969696; }
  .appointment-status.s_1 {
    background-color: #9DCC9B; }

.customer-box button {
  z-index: 1000; }

.companies-tab-list h6 {
  font-size: 15px;
  margin-bottom: 15px; }

.companies-tab-list textarea.overview {
  max-width: 100%;
  min-width: 100%;
  max-height: 20vh;
  min-height: 15vh; }

.customized-dialog-content .MuiTab-fullWidth {
  flex-basis: auto; }

.customized-dialog-content .customer-tab-card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 240px; }
  .customized-dialog-content .customer-tab-card .history-container {
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px; }
  .customized-dialog-content .customer-tab-card .card-info {
    box-shadow: none; }
    .customized-dialog-content .customer-tab-card .card-info h5 {
      margin: 10px 5px 10px 0; }
    .customized-dialog-content .customer-tab-card .card-info p {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin: 0; }
  .customized-dialog-content .customer-tab-card .card-box-avatar {
    width: 140px;
    height: 140px;
    font-size: 3em; }
  .customized-dialog-content .customer-tab-card th.MuiTableCell-stickyHeader {
    text-align: center;
    background-color: #FFFFFF; }
  .customized-dialog-content .customer-tab-card tbody.history-body td {
    font-size: 14px;
    text-align: center; }
  .customized-dialog-content .customer-tab-card .name {
    display: block;
    white-space: nowrap;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis; }

.customized-dialog-content .no-staff-information {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center; }

.customized-dialog-content #customer-tab-panel .MuiGrid-container {
  position: relative;
  padding: 0;
  width: 100%;
  min-height: 230px;
  margin: 0; }
  .customized-dialog-content #customer-tab-panel .MuiGrid-container .card-info {
    padding: 30px 20px 0 20px; }

.customer-tab-card.MuiPaper-elevation1 {
  box-shadow: none; }

.card-action-area.MuiCardContent-root {
  width: 100%;
  padding: 0; }

.card-action-area.MuiCardContent-root:last-child {
  padding-bottom: 0; }

.preview-email {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis; }

.staff-preview-name {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis; }

.MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight {
  opacity: 0 !important; }

.service-icon {
  width: 22px;
  height: 20px; }

.disable-industries * {
  cursor: no-drop;
  background: #fff;
  border-radius: 10%; }
  .disable-industries * .industries * {
    height: auto;
    color: #d5d5d5; }

.invite-staff-text,
.invite-customer-text {
  font-size: 15px;
  font-weight: 400; }
  .invite-staff-text .invite-staff-text-name,
  .invite-staff-text .invite-customer-text-name,
  .invite-customer-text .invite-staff-text-name,
  .invite-customer-text .invite-customer-text-name {
    color: #1a85ff; }

.turn-off {
  cursor: no-drop !important;
  -webkit-filter: opacity(0.5);
          filter: opacity(0.5);
  background-color: #e5e5e5 !important;
  color: #000 !important; }
  .turn-off::before {
    left: unset !important;
    background-color: #ccc !important; }

.company-main-content .company-name-content {
  font-size: 14px;
  color: #1E140A;
  font-weight: bold;
  margin-top: 14px; }

#customized-menu ul li {
  padding: 0 20px; }

.active-card-box {
  padding: 0 !important;
  text-align: center; }
  .active-card-box .MuiList-root.MuiMenu-list.MuiList-padding {
    width: 120px !important; }
  .active-card-box .active-menu-btn {
    padding: 2px 10px;
    background-color: #FFFFFF;
    border: 1px solid #0282DA;
    color: #0282DA;
    box-shadow: none;
    width: 120px;
    height: 30px; }
    .active-card-box .active-menu-btn:hover {
      color: #FFFFFF;
      background-color: #0282DA; }

.content-card-box {
  padding: 8px 12px 8px 16px !important;
  text-align: center; }
  .content-card-box .invited-select {
    margin-top: 10px; }
  .content-card-box .invite-btn,
  .content-card-box .invite-menu-btn,
  .content-card-box .invited-menu-btn {
    border: 1px solid #006ce5;
    margin: 13.5px 0;
    padding: 2px 30px;
    background-color: #3486e3;
    color: #FFFFFF; }
    .content-card-box .invite-btn:hover,
    .content-card-box .invite-menu-btn:hover,
    .content-card-box .invited-menu-btn:hover {
      background-color: #62B1E8;
      border: 1px solid #62B1E8; }
  .content-card-box .invited-menu-btn {
    padding: 2px 10px;
    background-color: #FFFFFF;
    color: #1a85ff;
    box-shadow: none; }
    .content-card-box .invited-menu-btn:hover {
      color: #FFFFFF;
      background-color: #3486e3; }
  .content-card-box:last-child {
    padding-bottom: 8px !important; }
  .content-card-box .registered-icon {
    font-size: 16px;
    opacity: .5;
    margin: 15.5px 0; }
  .content-card-box .resend-text {
    font-size: 14px;
    margin: 5px 0 0 0; }

.staff-tab-loading.img-header {
  margin-top: 30px; }

.img-header .MuiBadge-badge {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-color: #006ce5;
  background-color: transparent; }
  .img-header .MuiBadge-badge svg {
    font-size: 2.5em;
    color: #0282DA;
    border-radius: 50%;
    background-color: #FFFFFF;
    box-shadow: 0 0 0 #0282DA; }

.add-logo-content#dropzone .dropzone {
  padding: 25px !important;
  height: 165px;
  background-image: url(https://dev.settime.io/static/media/add-img.4dfd964b.png);
  background-color: #e5e5e5;
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 3em; }

.add-logo-content .removed-logo {
  display: none !important; }

.add-gallery-content#dropzone .dropzone {
  min-width: 150px;
  max-width: 160px;
  height: 160px;
  background-image: url(https://dev.settime.io/static/media/add-img.4dfd964b.png);
  background-color: #e5e5e5;
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 3em;
  margin: 10px; }

.thumb-style div {
  width: 100%;
  height: 100% !important; }

.company-link svg,
.company-email svg {
  opacity: .3; }

.thumb-style .remove-industry-avatar svg {
  position: absolute;
  right: 0; }

.thumb-style-logo div {
  padding: 0 !important;
  height: 165px; }

#mui-component-select-phone_code {
  display: flex; }

#mui-component-select-phoneCode {
  display: flex; }

.country-flag {
  margin-right: 5px; }

.settings-box {
  display: flex; }

.settings-calendarBox {
  margin-bottom: 20px;
  padding: 16px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12); }

#settings-followBox {
  padding: 0; }

.settings-appointmentBox {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 16px; }

.settings-appointments {
  margin: 16px 0; }

.category-rows {
  max-height: calc(100vh - 64px - 65px - 64px - 48px);
  overflow-y: auto; }

.company-gallery {
  margin: 22px; }
  .company-gallery section.gallery {
    display: flex;
    justify-content: start; }
  .company-gallery .aside {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }

.thumbGallery {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin: 10px;
  width: 160px;
  height: 160px;
  padding: 4px;
  box-sizing: border-box; }
  .thumbGallery .thumbInner {
    position: relative;
    display: flex;
    min-width: 0;
    overflow: hidden; }
  .thumbGallery .img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.error-content {
  display: flex;
  width: 550px;
  justify-content: space-between; }

.add-logo-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left; }
  .add-logo-content .rect-shape {
    border-radius: 2px; }
  .add-logo-content label {
    margin-bottom: 0;
    cursor: pointer; }
    .add-logo-content label.switch {
      height: 80px;
      width: 42%; }
    .add-logo-content label img {
      border-radius: 50%;
      border: 1px solid #efefef;
      height: 80px; }

.top-bar-personal .dropdown .btn.actions {
  border-radius: 5px; }

.staff-section {
  margin-top: 10px;
  margin-left: 15px; }

.staff-section.col-auto {
  display: flex;
  padding: 0 !important; }

.create-staff-modal-dialog {
  max-width: 500px; }

.create-staff-modal-dialog .btn {
  height: 40px;
  border-radius: 5px; }

.create-staff-modal-dialog .css-2b097c-container {
  margin-bottom: 10px; }

.create-staff-modal-dialog .table th {
  font-size: 13px;
  color: #555; }

.active-staff {
  width: 60px;
  background-color: #0282DA;
  border: 1px solid #0282DA;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .active-staff p {
    color: white; }

.edit-permissions-modal-dialog {
  max-width: 500px !important; }
  .edit-permissions-modal-dialog .modal-header h4 {
    font-size: 20px;
    margin: 0; }
  .edit-permissions-modal-dialog .modal-body {
    padding: 0 1rem; }
  .edit-permissions-modal-dialog table tr th {
    font-size: 15px;
    color: #333;
    width: 50%;
    border-top: none; }
    .edit-permissions-modal-dialog table tr th div {
      font-size: 14px; }
  .edit-permissions-modal-dialog .modal-footer .btn {
    width: 110px; }

.modal-footer {
  border-top: none !important; }

.top-bar-actions {
  width: 100%;
  z-index: 100; }
  .top-bar-actions .action-content .filter-select {
    width: 13%;
    margin: 0 20px; }
  .top-bar-actions .action-content .actions-tab button.actions {
    width: 100%;
    height: 40px; }
    .top-bar-actions .action-content .actions-tab button.actions:after {
      display: none; }

.permission-name {
  display: inline-block;
  width: 120px; }

.col-auto.customers-line {
  display: flex;
  flex-wrap: wrap;
  padding: 0 !important; }
  .col-auto.customers-line .customer-item-btn {
    position: absolute;
    top: 0;
    right: 0;
    color: #0282DA;
    padding: 5px 0 0 0; }
  .col-auto.customers-line .customer-item .actions-dropdown {
    position: absolute;
    top: 5px;
    right: 15px; }
    .col-auto.customers-line .customer-item .actions-dropdown .dropdown-toggle {
      flex-direction: row;
      align-items: center; }
      .col-auto.customers-line .customer-item .actions-dropdown .dropdown-toggle:before, .col-auto.customers-line .customer-item .actions-dropdown .dropdown-toggle i {
        margin-right: 2px; }
  .col-auto.customers-line .customer-item:last-child {
    margin-right: 0; }

.action-btn.customer-link {
  color: #000000; }
  .action-btn.customer-link:hover {
    color: #1a85ff; }

.filter-date {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0; }

.modal-body {
  padding: 0 5px 5px 10px !important; }

.error-message {
  position: relative !important; }

.dropdown-item p {
  font-size: 12px !important;
  margin: 0 !important;
  padding: 0 !important; }

.filter-menu {
  right: 0;
  width: 16%; }

.closed {
  color: #A4A4A4;
  margin-left: 15px; }

#info-tab {
  margin: unset;
  max-width: 1000px;
  width: 100%;
  padding: 25px;
  border-radius: 2px; }
  #info-tab .business-details {
    width: 100%; }
  #info-tab .form-group {
    margin: 0; }
  #info-tab .form-column-select {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 8px; }
    #info-tab .form-column-select .select-content .form-item-select {
      height: 100%; }

#working-schedule-content {
  padding: 32px;
  width: 100%;
  border-radius: 2px;
  margin: 0; }

.general-schedule {
  margin-top: 20px; }

#working-schedule-content #tableTitle {
  margin-left: 10px;
  padding: 10px 0; }

.working-schedule-box {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12);
  padding: 10px 10px;
  margin-bottom: 10px; }

.working-schedule-title {
  border-bottom: 1px solid lightgray; }

.company-box {
  padding: 10px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12); }

.staff-box {
  padding: 10px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12); }

.company-staff-date #company {
  padding-right: 10px; }

.staff-box,
.breaking-schedule,
.working-schedule {
  margin-top: 2px; }

.button-grid-cont {
  max-height: 84px; }

.hours-select select {
  width: 85px; }

@media (max-width: 1100px) {
  #tab-main-parent {
    width: 100%; } }

@media all and (max-width: 493px) {
  .tabs-navbar {
    margin-bottom: 10px; }
  .invite-btn {
    margin-bottom: 10px; } }

@media all and (max-width: 1121px) {
  .select-companies {
    margin-bottom: 20px; } }

@media all and (max-width: 1121px) {
  .second-buttons {
    display: none !important; } }

.menu-resp {
  display: none; }
  @media all and (max-width: 1121px) {
    .menu-resp {
      display: block; } }
  @media all and (max-width: 942px) {
    .menu-resp {
      margin-bottom: 10px; } }

.long-menu .MuiPaper-elevation8 {
  box-shadow: 0 1px 1px -1px black, 0px 1px 0px 0px gainsboro, 0px 1px 2px 0px gainsboro; }

#details .tab-list {
  margin: 20px 0; }
  #details .tab-list #info .react-datepicker-wrapper {
    width: 100%; }

#details #history {
  width: 100%; }
  #details #history .message {
    font-size: 24px; }

.content .datetime-content {
  position: relative;
  width: 100%; }
  .content .datetime-content #datetime {
    position: absolute;
    bottom: 0;
    top: 10px;
    left: 10px; }

.MuiAccordion-root:before {
  left: unset !important; }

.accordion {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 5px;
  background-color: #F6F6F7 !important;
  box-shadow: 1px 5px 24px rgba(68, 102, 242, 0.05); }
  @media all and (max-width: 1199px) {
    .accordion {
      flex-direction: column; } }
  @media all and (max-width: 1199px) {
    .accordion {
      flex-direction: column; } }
  @media all and (max-width: 1199px) {
    .accordion {
      flex-direction: column; } }
  .accordion .MuiCollapse-hidden {
    visibility: visible !important; }
    @media all and (max-width: 1199px) {
      .accordion .MuiCollapse-hidden {
        visibility: hidden !important; } }
  .accordion .MuiCollapse-container {
    overflow: visible !important; }
  .accordion .MuiAccordionSummary-root {
    display: none !important; }
    @media all and (max-width: 1199px) {
      .accordion .MuiAccordionSummary-root {
        display: flex !important; } }
    .accordion .MuiAccordionSummary-root:hover {
      background-color: rgba(0, 120, 255, 0.07); }
  .accordion .MuiAccordionDetails-root {
    padding: 0 !important; }
  .accordion .MuiAccordion-rounded:first-child {
    flex: 1 1;
    margin-top: 0 !important; }
    .accordion .MuiAccordion-rounded:first-child .MuiTypography-body1 {
      width: 100%; }
    @media all and (max-width: 1199px) {
      .accordion .MuiAccordion-rounded:first-child {
        width: 100% !important; }
        .accordion .MuiAccordion-rounded:first-child .MuiTypography-body1 {
          width: 100% !important; } }
  .accordion .MuiAccordion-rounded:nth-child(2) {
    width: 100% !important;
    flex: 4 1; }

.main-message-content {
  height: calc(100vh - 130px); }
  .main-message-content img {
    width: 410px; }
    @media all and (max-width: 550px) {
      .main-message-content img {
        max-width: 320px;
        width: 100%; } }

.main-chat-content {
  overflow: hidden;
  height: calc(100vh - 130px); }
  @media all and (max-width: 1199px) {
    .main-chat-content {
      height: auto; } }

.accordion-last-item {
  flex: 1 1;
  overflow: hidden; }

.menu-item.messages .general-content .users .users-item {
  border-bottom: 0.5px solid #CBCDCF;
  padding: 11px 24px 12px 24px;
  width: 100%; }
  @media all and (max-width: 576px) {
    .menu-item.messages .general-content .users .users-item {
      padding: 11px 24px 12px 24px !important; } }
  .menu-item.messages .general-content .users .users-item:active {
    background: rgba(0, 120, 255, 0.07); }
    .menu-item.messages .general-content .users .users-item:active .last-message {
      display: none; }
    .menu-item.messages .general-content .users .users-item:active .user-name {
      align-self: center; }

.menu-item.messages .general-content .users .user-name a {
  font: 400 14px/25px "Roboto", sans-serif;
  color: #000000; }

.menu-item.messages .general-content .users .last-message {
  font: 400 12px/25px "Roboto", sans-serif;
  color: #5D5D5D;
  margin-bottom: 0;
  margin-top: -10px; }

.menu-item.messages .general-content .panel .main-content {
  width: 100% !important; }
  @media all and (max-width: 1199px) {
    .menu-item.messages .general-content .panel .main-content {
      min-height: 0 !important; } }
  @media all and (max-width: 575px) {
    .menu-item.messages .general-content .panel .main-content {
      margin-top: 0 !important; } }
  .menu-item.messages .general-content .panel .main-content .left-content, .menu-item.messages .general-content .panel .main-content .right-content {
    padding: 0; }
    .menu-item.messages .general-content .panel .main-content .left-content .top-content, .menu-item.messages .general-content .panel .main-content .right-content .top-content {
      width: 100%; }
  .menu-item.messages .general-content .panel .main-content .accordion-last-item {
    overflow: auto; }
    @media all and (max-width: 1199px) {
      .menu-item.messages .general-content .panel .main-content .accordion-last-item {
        overflow: unset; } }
  .menu-item.messages .general-content .panel .main-content .content-chat {
    width: 100%;
    min-height: calc(100vh - 130px);
    position: relative; }
    .menu-item.messages .general-content .panel .main-content .content-chat .chat {
      height: 100%; }
      .menu-item.messages .general-content .panel .main-content .content-chat .chat .messages {
        display: grid;
        grid-auto-rows: -webkit-min-content;
        grid-auto-rows: min-content;
        grid-template-rows: auto;
        align-items: flex-end;
        padding: 29px;
        overflow: auto;
        height: calc(100vh - 335px); }
        @media all and (max-width: 576px) {
          .menu-item.messages .general-content .panel .main-content .content-chat .chat .messages {
            height: calc(100vh - 394px); } }
        .menu-item.messages .general-content .panel .main-content .content-chat .chat .messages .sent-message {
          margin-left: auto;
          margin-bottom: 15px;
          font: 400 14px/25px "Roboto", sans-serif;
          color: #FFFFFF;
          list-style-type: none;
          background: rgba(0, 119, 255, 0.5);
          border-radius: 8px 8px 0 8px;
          padding: 9px 15px 10px 15px;
          max-width: 286px; }
        .menu-item.messages .general-content .panel .main-content .content-chat .chat .messages .received-message {
          font: 400 14px/25px "Roboto", sans-serif;
          color: #000000;
          list-style-type: none;
          background: rgba(202, 202, 203, 0.5);
          border-radius: 8px 8px 8px 0;
          padding: 9px 15px 10px 15px;
          max-width: 286px;
          margin-right: auto;
          margin-bottom: 15px; }
    .menu-item.messages .general-content .panel .main-content .content-chat .message-send {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 0 29px;
      overflow: auto; }
      @media all and (max-width: 991px) {
        .menu-item.messages .general-content .panel .main-content .content-chat .message-send {
          bottom: 4%; } }
      @media all and (max-width: 576px) {
        .menu-item.messages .general-content .panel .main-content .content-chat .message-send {
          flex-direction: column-reverse; } }
      .menu-item.messages .general-content .panel .main-content .content-chat .message-send .send-items {
        display: flex;
        margin-right: 17px;
        margin-top: 44px; }
        @media all and (max-width: 576px) {
          .menu-item.messages .general-content .panel .main-content .content-chat .message-send .send-items {
            margin-top: 10px; } }
        .menu-item.messages .general-content .panel .main-content .content-chat .message-send .send-items .photo-item {
          margin-left: 19px;
          margin-right: 21px; }
      .menu-item.messages .general-content .panel .main-content .content-chat .message-send .send-message {
        display: flex;
        max-width: 862px;
        width: 100%; }
      .menu-item.messages .general-content .panel .main-content .content-chat .message-send .send-btn {
        border: none;
        background: none;
        margin-top: 40px; }
      .menu-item.messages .general-content .panel .main-content .content-chat .message-send .type-message {
        margin-right: 10px;
        margin-left: 10px;
        min-height: 93px;
        width: 100%; }
        .menu-item.messages .general-content .panel .main-content .content-chat .message-send .type-message textarea {
          overflow: auto;
          resize: none;
          width: 100%;
          height: 100%;
          padding: 15px 13px;
          outline: none;
          border: 1px solid #A4A4A4;
          border-radius: 5px;
          font: 500 14px/19px "Roboto", sans-serif;
          color: #000000; }
  .menu-item.messages .general-content .panel .main-content .no-chat-content {
    text-align: center;
    margin: 20px auto; }
    .menu-item.messages .general-content .panel .main-content .no-chat-content p {
      margin-bottom: 25px;
      font-size: 25px;
      color: #259BDF; }
  .menu-item.messages .general-content .panel .main-content .chat-content {
    width: 80%;
    min-height: calc(100vh - 130px); }
    .menu-item.messages .general-content .panel .main-content .chat-content .top-content .row {
      height: 100%; }
      .menu-item.messages .general-content .panel .main-content .chat-content .top-content .row .right-top-item {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #d0d0d0;
        border-bottom: 1px solid #d0d0d0; }
        .menu-item.messages .general-content .panel .main-content .chat-content .top-content .row .right-top-item:last-child {
          border-right: none;
          justify-content: flex-start; }
        .menu-item.messages .general-content .panel .main-content .chat-content .top-content .row .right-top-item .main {
          display: flex;
          justify-content: space-around; }
          .menu-item.messages .general-content .panel .main-content .chat-content .top-content .row .right-top-item .main .image-content {
            display: flex;
            align-items: center;
            margin: 8px 12px 8px 0; }
            .menu-item.messages .general-content .panel .main-content .chat-content .top-content .row .right-top-item .main .image-content svg {
              color: #0282DA; }
            .menu-item.messages .general-content .panel .main-content .chat-content .top-content .row .right-top-item .main .image-content img {
              width: 60px;
              height: 60px;
              border-radius: 20px; }
    .menu-item.messages .general-content .panel .main-content .chat-content .chat-content {
      padding-top: 20px;
      max-height: calc(100% - 360px);
      min-height: calc(100% - 360px);
      overflow-y: auto; }
      .menu-item.messages .general-content .panel .main-content .chat-content .chat-content .messages {
        padding: 0 15px; }
        .menu-item.messages .general-content .panel .main-content .chat-content .chat-content .messages .message-item {
          margin-bottom: 15px; }
          .menu-item.messages .general-content .panel .main-content .chat-content .chat-content .messages .message-item.right {
            justify-content: flex-end; }
            .menu-item.messages .general-content .panel .main-content .chat-content .chat-content .messages .message-item.right .message-content {
              box-shadow: 0 3px 6px #000000;
              border-radius: 16px 0 16px 16px;
              opacity: 1;
              background: #0282DA;
              color: #fff; }
              .menu-item.messages .general-content .panel .main-content .chat-content .chat-content .messages .message-item.right .message-content .text {
                word-break: break-word; }
        .menu-item.messages .general-content .panel .main-content .chat-content .chat-content .messages .staff-image {
          width: 44px;
          height: 44px;
          margin-right: 10px; }
          .menu-item.messages .general-content .panel .main-content .chat-content .chat-content .messages .staff-image img {
            width: 100%;
            border-radius: 50%; }
        .menu-item.messages .general-content .panel .main-content .chat-content .chat-content .messages .event-text {
          color: #0282DA; }
    .menu-item.messages .general-content .panel .main-content .chat-content .message-submit {
      width: 90%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-top: 20px;
      height: 260px; }
      .menu-item.messages .general-content .panel .main-content .chat-content .message-submit textarea {
        width: 100%;
        padding: 10px;
        background: #FFFFFF 0 0 no-repeat padding-box;
        box-shadow: 0 3px 6px #000000;
        border: none;
        border-radius: 16px;
        resize: none;
        color: #B6B8C7; }
        .menu-item.messages .general-content .panel .main-content .chat-content .message-submit textarea::-webkit-input-placeholder {
          color: #B6B8C7; }
        .menu-item.messages .general-content .panel .main-content .chat-content .message-submit textarea::placeholder {
          color: #B6B8C7; }
        .menu-item.messages .general-content .panel .main-content .chat-content .message-submit textarea:focus {
          outline: none; }
      .menu-item.messages .general-content .panel .main-content .chat-content .message-submit button {
        align-self: flex-end;
        background: #0282DA;
        border: 0;
        padding: 5px 70px;
        margin-bottom: 15px;
        border-radius: 20px;
        color: #fff; }

.content-message {
  margin-bottom: 30px;
  margin-right: 16px; }
  .content-message .text {
    position: relative;
    box-shadow: 0 3px 6px #000000;
    background: #202020;
    border-radius: 8px 8px 8px 0;
    opacity: 0.5;
    word-break: break-word;
    font: 500 14px/25px "Roboto", sans-serif;
    padding: 10px;
    color: #FFFFFF; }
  .content-message .time {
    text-align: left;
    color: #A4A4A4;
    font: 400 12px/150% "Roboto", sans-serif;
    margin-left: 47px; }

.message-detail {
  visibility: hidden;
  margin-right: 10px; }

.content-message:hover .message-detail {
  visibility: visible !important; }

.message-content:hover .message-detail {
  visibility: visible !important; }

.message-content {
  margin-bottom: 30px;
  margin-right: 16px; }
  .message-content .text {
    word-break: break-word;
    font: 500 14px/25px "Roboto", sans-serif;
    box-shadow: 0 3px 6px #000000;
    background: rgba(0, 120, 255, 0.5);
    border-radius: 8px 8px 0 8px;
    padding: 10px;
    color: #FFFFFF; }
  .message-content .time {
    text-align: right;
    color: #A4A4A4;
    font: 400 12px/150% "Roboto", sans-serif;
    margin-right: 47px; }

.message-item.left .staff-image {
  margin-right: 12px; }

.message-item.right .staff-image {
  margin-left: 12px; }

.staff-image {
  width: 35px;
  height: 35px; }
  .staff-image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; }

.left-top-content {
  margin: 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .left-top-content .icon-link {
    color: #8c949d;
    transition: .5s; }
    .left-top-content .icon-link:hover {
      color: #1a85ff; }

.left-content {
  width: 100%;
  min-width: 255px;
  border-right: 1px solid #CBCDCF;
  min-height: calc(100vh - 130px); }
  @media all and (max-width: 1199px) {
    .left-content {
      min-height: 0; } }
  .left-content .top-content {
    display: flex;
    justify-content: center;
    align-items: center; }
    .left-content .top-content .search {
      margin-bottom: 10px;
      width: 82%;
      position: relative; }
      @media all and (max-width: 1199px) {
        .left-content .top-content .search {
          width: 50%; } }
      .left-content .top-content .search .search-icon {
        position: absolute;
        top: 17px;
        left: 0; }
  .left-content .messages-main-content {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 130px);
    overflow-y: auto; }
    .left-content .messages-main-content .message-item {
      position: relative;
      display: flex;
      justify-content: start;
      align-items: center;
      width: 100%;
      min-height: 72px;
      cursor: pointer;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding: 0 12px; }
      .left-content .messages-main-content .message-item .image-content {
        display: flex;
        align-items: center;
        margin: 8px 12px 8px 0; }
        .left-content .messages-main-content .message-item .image-content .user-avatar {
          width: 50px;
          height: 50px; }
      .left-content .messages-main-content .message-item .info-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .left-content .messages-main-content .message-item .info-content h6 {
          width: 213px; }
        .left-content .messages-main-content .message-item .info-content span {
          font-size: 11px;
          margin-right: auto; }
      .left-content .messages-main-content .message-item.active {
        background-color: rgba(0, 0, 0, 0.12); }
        .left-content .messages-main-content .message-item.active .info-content h6 {
          color: #0282DA !important; }
      .left-content .messages-main-content .message-item .unread-dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #0282DA;
        margin-right: 10px; }
  .left-content .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    margin-top: 20px; }
    .left-content .pagination .content {
      display: flex;
      justify-content: center;
      align-items: center; }
      .left-content .pagination .content p {
        margin: 0 3px;
        cursor: pointer;
        color: #0282DA;
        padding: 7px 14px;
        background: #fff;
        box-shadow: 0 3px 6px #000000;
        border-radius: 40px;
        font-size: 13px; }
        .left-content .pagination .content p svg {
          width: .5em; }
        .left-content .pagination .content p span {
          padding: 2px 2px;
          margin-top: 1px;
          display: inline-block; }
        .left-content .pagination .content p.active {
          background: #0282DA;
          color: #fff; }
        .left-content .pagination .content p.disabled {
          background-color: #cccccc;
          color: #666666;
          cursor: no-drop; }

.right-content {
  width: 100%;
  border-left: 1px solid #CBCDCF;
  min-height: calc(100vh - 140px); }
  @media all and (max-width: 1199px) {
    .right-content {
      border: none; } }
  .right-content .more {
    text-align: right;
    padding: 22px 20px 22px 0; }
  .right-content .details {
    max-width: 294px; }
    @media all and (max-width: 1199px) {
      .right-content .details {
        margin: 0 auto; } }
    .right-content .details .company-image {
      width: 100%;
      margin-bottom: 50px; }
      .right-content .details .company-image img {
        width: 100%;
        max-height: 200px; }
    .right-content .details .about-company {
      display: flex; }
      .right-content .details .about-company ul:nth-child(1) {
        margin-right: 30px; }
      .right-content .details .about-company ul {
        padding-left: 22px; }
        .right-content .details .about-company ul li {
          list-style-type: none;
          margin-bottom: 28px;
          text-align: left; }
          .right-content .details .about-company ul li .details-item {
            font: 400 12px/25px "Roboto", sans-serif;
            color: #A4A4A4;
            margin-bottom: 0; }
          .right-content .details .about-company ul li .information {
            font: 400 14px/25px "Roboto", sans-serif;
            color: #000000;
            margin-bottom: 0;
            margin-top: -5px; }
    .right-content .details .calendar {
      margin-top: 50px; }
      .right-content .details .calendar .react-calendar {
        border: none; }
      .right-content .details .calendar .react-calendar__viewContainer {
        border: 1px solid #D3D1D1; }
      .right-content .details .calendar .react-calendar__month-view__weekdays {
        border-bottom: 1px solid #D3D1D1; }
      .right-content .details .calendar .react-calendar__navigation {
        margin-bottom: 0;
        justify-content: center; }
      .right-content .details .calendar .react-calendar__navigation__label {
        max-width: 116px;
        padding: 0; }
      .right-content .details .calendar .react-calendar__navigation button {
        color: #0282DA;
        font: 400 14px/11px "Roboto", sans-serif;
        padding: 0; }
      .right-content .details .calendar .react-calendar__month-view__weekdays__weekday {
        color: #A4A4A4;
        font: 500 10px/4px "Roboto", sans-serif; }
      .right-content .details .calendar .react-calendar__tile {
        color: #000000;
        font: 400 10px/13px "Roboto", sans-serif; }
      .right-content .details .calendar .react-calendar__tile.react-calendar__month-view__days__day {
        padding: 8px; }
      .right-content .details .calendar .react-calendar__navigation__prev-button,
      .right-content .details .calendar .react-calendar__navigation__next-button {
        min-width: 0; }
      .right-content .details .calendar .react-calendar__navigation__prev-button:hover,
      .right-content .details .calendar .react-calendar__navigation__next-button:hover,
      .right-content .details .calendar .react-calendar__navigation__label:hover,
      .right-content .details .calendar .react-calendar__navigation__label:hover,
      .right-content .details .calendar .react-calendar__month-view__days__day:hover {
        background: none; }
      .right-content .details .calendar .react-calendar__month-view__days__day--neighboringMonth {
        color: #A4A4A4; }
      .right-content .details .calendar .react-calendar__tile--active {
        background: #ECF4FF; }
      .right-content .details .calendar .react-calendar__navigation__prev2-button, .right-content .details .calendar .react-calendar__navigation__next2-button {
        display: none; }

.users-detail {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #CBCDCF;
  padding: 0 12px;
  min-height: 72px; }
  .users-detail .user {
    display: flex;
    width: 100%; }
    .users-detail .user .user-image {
      margin-right: 23px !important;
      align-self: center; }
    .users-detail .user .user-name a {
      font: 400 16px/25px "Roboto", sans-serif;
      color: #000000; }
    .users-detail .user .user-name .last-seen {
      font: 400 14px/25px "Roboto", sans-serif;
      color: #A4A4A4;
      margin-bottom: 0;
      margin-top: -8px; }
  .users-detail .details-icon {
    display: none;
    align-self: center; }

@media all and (max-width: 1199px) {
  .messages-loading {
    display: none !important; } }

.messages-load-small {
  display: none; }
  @media all and (max-width: 1199px) {
    .messages-load-small {
      display: block; } }

.top-avatar {
  display: flex;
  align-items: center;
  margin: 8px 12px 8px 0; }
  .top-avatar .message-avatar {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    object-fit: cover; }

.main-content .notifications-item {
  padding: 15px 0;
  border-bottom: 2px solid #e4ebf1; }
  .main-content .notifications-item.unread {
    background: #edf2fa; }
  .main-content .notifications-item .notification-content {
    font-size: 22px; }
    .main-content .notifications-item .notification-content .calendar {
      border-radius: 50%;
      margin-top: 5px;
      background-color: #ecb900;
      color: #fff;
      padding: 6px 10px !important; }
    .main-content .notifications-item .notification-content .activity-content {
      display: flex;
      align-items: center;
      padding: 0 10px;
      position: relative; }
      .main-content .notifications-item .notification-content .activity-content .notification-message {
        margin: 0 10px;
        cursor: pointer; }
        .main-content .notifications-item .notification-content .activity-content .notification-message:hover {
          text-decoration: underline; }
      .main-content .notifications-item .notification-content .activity-content .notification-status {
        color: white;
        font-size: 17px;
        background: gray;
        padding: 0 5px;
        border-radius: 10px;
        border: 1px solid black;
        margin: 0 15px; }
      .main-content .notifications-item .notification-content .activity-content .notification-type {
        color: white;
        margin-left: 10px;
        padding: 3px 10px;
        border-radius: 10px;
        border: none; }
        .main-content .notifications-item .notification-content .activity-content .notification-type.accepted {
          background: #c1ff5d; }
        .main-content .notifications-item .notification-content .activity-content .notification-type.created {
          background: #5dffd9; }
        .main-content .notifications-item .notification-content .activity-content .notification-type.cancelled {
          background: #ff5d70; }
      .main-content .notifications-item .notification-content .activity-content .span-time {
        text-align: end;
        flex-grow: 20;
        font-size: 15px; }
      .main-content .notifications-item .notification-content .activity-content .activity-buttons {
        flex-grow: 4;
        text-align: end; }
        .main-content .notifications-item .notification-content .activity-content .activity-buttons button {
          width: 100px;
          color: #fff; }
          .main-content .notifications-item .notification-content .activity-content .activity-buttons button.accept {
            background-color: #0282DA; }
          .main-content .notifications-item .notification-content .activity-content .activity-buttons button.cancel {
            background-color: #cdd4da; }

.notifications .content {
  height: 100% !important;
  min-width: 426px; }
  @media all and (max-width: 350px) {
    .notifications .content {
      min-width: 270px; } }

.notification-title {
  font: normal 600 14px/19px "Nunito Sans", sans-serif;
  color: #393939;
  margin: 0; }

.notification-option-menu .MuiMenu-paper {
  left: unset !important;
  right: 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: #FFFFFF; }

.notification-accepted {
  font: normal 400 14px/19px "Nunito Sans", sans-serif;
  color: #393939;
  margin: 0 0 5px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.accepted-time {
  font: normal 400 12px/16px "Nunito Sans", sans-serif;
  color: #707070; }

.menu-item {
  padding: 0; }

.menu-delete-btn {
  color: #F12B2C;
  font: normal 400 16px/20px Nunito Sans, sans-serif;
  margin-left: 12px; }

.see-all {
  justify-content: center !important;
  color: #0282DA !important;
  background-color: transparent !important;
  box-shadow: none !important;
  display: flex !important;
  margin: 0 auto !important;
  font: normal 600 14px/19px "Nunito Sans", sans-serif;
  padding: 7px 10px !important; }

.buttons-notification {
  width: 100%;
  max-height: 700px;
  overflow: auto; }
  .buttons-notification::-webkit-scrollbar {
    width: 0; }
  .buttons-notification:focus {
    outline: none; }

.show-less-box {
  background: #FFFFFF;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px; }

.buttons-notification::-webkit-scrollbar {
  display: inherit; }

.buttons-notification.show-more {
  overflow: auto;
  max-height: 680px; }

.buttons-notification.show-less {
  overflow: hidden;
  max-height: 430px; }

.done-icon {
  align-self: center; }

.item-box {
  display: flex;
  grid-gap: 15px;
  gap: 15px; }
  .item-box .seen-avatar-box {
    display: flex;
    align-items: center;
    grid-gap: 7px;
    gap: 7px; }

.notification {
  max-width: 500px;
  width: 100%;
  max-height: 80vh;
  z-index: 99; }
  .notification .notification-content {
    max-height: 80vh;
    display: flex;
    flex-direction: column; }

.notification-item {
  padding: 10px 10px 0 10px;
  position: relative; }
  .notification-item.unread .unread-notification {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #0282DA; }
  .notification-item:hover {
    background-color: #e5f1ff;
    cursor: pointer; }
  .notification-item .content {
    border-bottom: 1px solid #E9E9E9;
    padding-bottom: 10px; }
  .notification-item .notification-buttons {
    display: flex;
    align-items: center;
    justify-content: end;
    grid-gap: 8px;
    gap: 8px;
    padding: 0 16px; }
    .notification-item .notification-buttons .cancel-btn {
      border: 1px solid #A4A4A4;
      border-radius: 4px;
      width: 100px;
      height: 36px;
      color: #A4A4A4;
      font: normal 500 14px/19px Nunito Sans, sans-serif;
      background-color: #FFFFFF; }
    .notification-item .notification-buttons .accept-btn {
      border-radius: 4px;
      width: 100px;
      height: 36px;
      color: #FFFFFF;
      background-color: #0282DA;
      font: normal 500 14px/19px Nunito Sans, sans-serif; }

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px; }
  .notification-header .title-box p {
    color: #393939;
    font: normal 700 16px/22px Nunito Sans, sans-serif;
    margin-top: 0;
    margin-bottom: 0; }
  .notification-header .read-all-box a {
    color: #0282DA;
    font: normal 600 14px/19px Nunito Sans, sans-serif;
    cursor: pointer; }

#styles_servicesContainer__1VGuP {
  height: calc(100vh - 64px); }
  #styles_servicesContainer__1VGuP .styles_container__3TkLD {
    min-width: 200px;
    width: 100%; }
    #styles_servicesContainer__1VGuP .styles_container__3TkLD .styles_categoriesContainer__i5XPa {
      height: 100%;
      border-right: 1px solid #E3E5E6;
      width: 100%; }
      #styles_servicesContainer__1VGuP .styles_container__3TkLD .styles_categoriesContainer__i5XPa .styles_titleBox__1TWg9 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 22px; }
        #styles_servicesContainer__1VGuP .styles_container__3TkLD .styles_categoriesContainer__i5XPa .styles_titleBox__1TWg9 p {
          font-family: 'Nunito', sans-serif;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #393939;
          margin-bottom: 0;
          margin-top: 0; }
      #styles_servicesContainer__1VGuP .styles_container__3TkLD .styles_categoriesContainer__i5XPa .styles_categories__2XRA4 {
        padding: 0 8px;
        max-height: calc(100vh - 130px);
        overflow-y: auto; }
        #styles_servicesContainer__1VGuP .styles_container__3TkLD .styles_categoriesContainer__i5XPa .styles_categories__2XRA4::-webkit-scrollbar {
          width: 0;
          height: 0; }
  #styles_servicesContainer__1VGuP .styles_tableBox__1yFCF {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 151px);
    border-top: 1px solid #E9E9E9;
    width: 100%; }
    #styles_servicesContainer__1VGuP .styles_tableBox__1yFCF .styles_table-row__3p_9m {
      color: red; }

#styles_headerContainer__1ZptW .styles_header__caIJL {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 24px; }
  #styles_headerContainer__1ZptW .styles_header__caIJL .styles_searchBox__2zDra {
    max-width: 270px;
    width: 100%; }

.styles_fromText__Om1Sa {
  color: #A4A4A4;
  font: normal 500 14px/19px Nunito Sans, sans-serif; }

.styles_buttonBox__3k-pc {
  height: 39px; }
  .styles_buttonBox__3k-pc button {
    height: 100%;
    padding: 4px 16px;
    font: normal 500 14px/19px Nunito Sans, sans-serif; }

.styles_serviceModal__1rnuR .styles_content__26ShE .styles_imgBox__2H_xO {
  display: flex;
  justify-content: center;
  margin-bottom: 24px; }

.styles_serviceModal__1rnuR .styles_content__26ShE .styles_contentText__1IbZz {
  font: normal 400 14px/19px Nunito Sans, sans-serif;
  color: #393939;
  text-align: center;
  margin-bottom: 0; }
  .styles_serviceModal__1rnuR .styles_content__26ShE .styles_contentText__1IbZz .styles_serviceText__3ilxM {
    font-weight: 600;
    margin-bottom: 5px; }

.styles_serviceModal__1rnuR .styles_content__26ShE .styles_closeIcon__21Iu5 {
  align-items: center;
  justify-content: flex-end;
  display: flex; }
  .styles_serviceModal__1rnuR .styles_content__26ShE .styles_closeIcon__21Iu5 svg {
    cursor: pointer;
    color: #A4A4A4; }

.styles_serviceDrawer__3nQJs .styles_MuiDrawer-paperAnchorRight__74-yE {
  flex-direction: column;
  justify-content: space-between; }

.styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T {
  padding-left: 24px;
  padding-right: 24px;
  width: 100%; }
  .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_titleContent__35fW6 {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_titleContent__35fW6 .styles_title__ykB6M {
      color: #393939;
      font: normal 700 16px/22px Nunito Sans, sans-serif; }
    .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_titleContent__35fW6 .styles_closeIcon__1GdKU {
      cursor: pointer;
      color: #A4A4A4; }
  .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_section__3uqGh {
    margin-top: 32px; }
    .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_section__3uqGh .styles_timeTexts__uX9WS {
      color: #393939;
      font: normal 400 14px/19px Nunito Sans, sans-serif;
      margin-bottom: 16px; }
    .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_section__3uqGh .styles_bufferBox__3vJwa {
      margin-top: 20px;
      margin-bottom: 7px; }
  .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_secTitle__21Nyh {
    color: #393939;
    font: normal 700 14px/19px Nunito Sans, sans-serif; }
  .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_footer__24fH9 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0; }
    .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_footer__24fH9 .styles_buttonsBox___OF1s {
      display: flex;
      justify-content: end;
      align-items: center;
      grid-gap: 8px;
      gap: 8px; }
    .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_footer__24fH9 .styles_deleteBtnText__To4PF {
      color: #F12B2C;
      font: normal 600 14px/19px Nunito Sans, sans-serif;
      margin-left: 8px; }
  .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_input-content__QV4Cj {
    margin-bottom: 20px; }
    .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_input-content__QV4Cj .styles_MuiInputLabel-outlined__f10PR.styles_MuiInputLabel-marginDense__1s4kD {
      -webkit-transform: translate(14px, 18px) scale(1) !important;
              transform: translate(14px, 18px) scale(1) !important; }
    .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_input-content__QV4Cj .styles_MuiInputLabel-outlined__f10PR.styles_MuiInputLabel-shrink__3TGk2 {
      -webkit-transform: translate(14px, -6px) scale(0.75) !important;
              transform: translate(14px, -6px) scale(0.75) !important; }
    .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_input-content__QV4Cj .styles_input__1HSaf {
      width: 100%;
      border-color: #A4A4A4;
      border-radius: 4px;
      font-size: 16px; }
      .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_input-content__QV4Cj .styles_input__1HSaf input {
        font-size: 15px; }
      .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_input-content__QV4Cj .styles_input__1HSaf .styles_MuiInputBase-root__1WogL {
        height: 48px; }
    .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_input-content__QV4Cj .styles_label__1N4tz {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 16px;
      color: #393939; }
    .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_input-content__QV4Cj.styles_profession__2Wuu5 .styles_MuiInputBase-root__1WogL {
      height: 48px; }
  .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_dropzone__1fo6A {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 auto 30px;
    cursor: pointer; }
    .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_dropzone__1fo6A .styles_image__3LW_m {
      width: 100px;
      height: 100px;
      border-radius: 50px; }
    .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_dropzone__1fo6A .styles_action__2jhE7 {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 32px;
      height: 32px;
      border-radius: 50px;
      background: #A2D1F2;
      border: 2px solid #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center; }
      .styles_serviceDrawer__3nQJs .styles_drawerContent__33g_T .styles_dropzone__1fo6A .styles_action__2jhE7 svg {
        color: white; }

.styles_serviceDrawer__3nQJs .styles_save-btn__laXhd {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 20px; }
  .styles_serviceDrawer__3nQJs .styles_save-btn__laXhd button {
    width: 100%; }

body {
  background-color: #FFFFFF !important;
  overflow-x: hidden; }
  body .label-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    font-size: 15px;
    color: #495861;
    font-weight: 400; }
    body .label-container .radio-button {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      border-radius: 50%;
      border: 3px solid #ccc;
      margin-top: 1px; }
      body .label-container .radio-button:after {
        content: "";
        position: absolute;
        display: none; }
  body button:focus, body button:active {
    outline: none; }
  body input:-webkit-autofill,
  body input:-webkit-autofill:hover,
  body input:-webkit-autofill:focus,
  body textarea:-webkit-autofill,
  body textarea:-webkit-autofill:hover,
  body textarea:-webkit-autofill:focus,
  body select:-webkit-autofill,
  body select:-webkit-autofill:hover,
  body select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #f1f5f7 inset; }
  body input[type=number]::-webkit-inner-spin-button,
  body input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.center-content {
  overflow-x: hidden;
  overflow-y: auto;
  width: calc(100% - 73px); }
  @media (max-width: 576px) {
    .center-content {
      min-height: calc(100vh - 120px); } }
  @media all and (max-width: 599px) {
    .center-content {
      width: calc(100% - 56px); } }
  .center-content .menu-item {
    overflow: hidden; }
    @media (max-width: 576px) {
      .center-content .menu-item {
        min-height: calc(100vh - 120px); } }
    .center-content .menu-item.customers .left-content {
      padding-top: 20px; }
      .center-content .menu-item.customers .left-content .dropdown-toggle:after {
        display: none; }
      .center-content .menu-item.customers .left-content .dropdown-toggle {
        background: none;
        border: none;
        cursor: pointer; }
      .center-content .menu-item.customers .left-content .add-customer-btn {
        font-size: 11px;
        width: 100%;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 3px;
        cursor: pointer; }
      .center-content .menu-item.customers .left-content .top-content .add-customer-content {
        width: 280px;
        height: 240px;
        background: #fff;
        border-radius: 4px;
        border: 1px solid #ccc;
        padding: 0 !important; }
        .center-content .menu-item.customers .left-content .top-content .add-customer-content .top-header-content {
          padding: 7px 10px;
          background: #f1f5f7;
          font-size: 14px; }
        .center-content .menu-item.customers .left-content .top-content .add-customer-content .main-content {
          padding: 10px; }
          .center-content .menu-item.customers .left-content .top-content .add-customer-content .main-content .add-customer-btn {
            height: 40px;
            width: 50%;
            background-color: #0282DA;
            opacity: .8;
            color: #fff;
            font-size: 13px;
            border-radius: 6px; }
      .center-content .menu-item.customers .left-content .customers-list-content {
        border-radius: 5px;
        background: #eaeaea; }
        .center-content .menu-item.customers .left-content .customers-list-content .customer .photo {
          margin: 0 10px; }
          .center-content .menu-item.customers .left-content .customers-list-content .customer .photo img {
            border-radius: 50%;
            border: 1px solid #ccc; }
    .center-content .menu-item.customers .personal-info-content label {
      width: 80px;
      min-width: 80px;
      margin-bottom: 0; }
      .center-content .menu-item.customers .personal-info-content label.switch {
        height: 80px; }
      .center-content .menu-item.customers .personal-info-content label img {
        border-radius: 50%;
        border: 1px solid #cccccc;
        height: 80px; }
      .center-content .menu-item.customers .personal-info-content label:hover .upload-file {
        display: flex; }
      .center-content .menu-item.customers .personal-info-content label .upload-file {
        position: absolute;
        top: 24%;
        font-size: 22px;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #efefef;
        display: none;
        align-items: center;
        justify-content: center;
        left: 20%;
        cursor: pointer; }
    .center-content .menu-item.calendar #calendar-container {
      padding: 20px; }

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 22px; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 1px;
  background-color: white;
  transition: .4s; }

input:checked + .slider {
  background-color: #0282DA;
  opacity: .8; }

input:focus + .slider {
  box-shadow: 0 0 1px #0282DA;
  opacity: .9; }

/* Rounded sliders */
.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }

.add-staff-modal {
  width: 600px; }

.add-staff-modal-upload {
  padding: 0; }

.wizard-container .wizard-navigation.individual .nav,
.create-staff-modal-dialog .wizard-navigation.individual .nav,
.companies .wizard-navigation.individual .nav,
.modal-body .wizard-navigation.individual .nav,
#customers .wizard-navigation.individual .nav {
  justify-content: center; }

.wizard-container .wizard-navigation.individual .moving-tab,
.create-staff-modal-dialog .wizard-navigation.individual .moving-tab,
.companies .wizard-navigation.individual .moving-tab,
.modal-body .wizard-navigation.individual .moving-tab,
#customers .wizard-navigation.individual .moving-tab {
  left: 5%; }

.wizard-container .wizard-navigation li,
.create-staff-modal-dialog .wizard-navigation li,
.companies .wizard-navigation li,
.modal-body .wizard-navigation li,
#customers .wizard-navigation li {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center; }

.wizard-container .wizard-navigation .moving-tab,
.create-staff-modal-dialog .wizard-navigation .moving-tab,
.companies .wizard-navigation .moving-tab,
.modal-body .wizard-navigation .moving-tab,
#customers .wizard-navigation .moving-tab {
  background-color: #0282DA !important;
  box-shadow: 0 16px 26px -10px #0282DA, 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px #0282DA; }

.wizard-container .form-item,
.create-staff-modal-dialog .form-item,
.companies .form-item,
.modal-body .form-item,
#customers .form-item {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border-radius: 10px;
  height: 40px; }
  .wizard-container .form-item:active, .wizard-container .form-item:focus,
  .create-staff-modal-dialog .form-item:active,
  .create-staff-modal-dialog .form-item:focus,
  .companies .form-item:active,
  .companies .form-item:focus,
  .modal-body .form-item:active,
  .modal-body .form-item:focus,
  #customers .form-item:active,
  #customers .form-item:focus {
    outline: none !important;
    border-color: #ced4da !important;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important; }
  .wizard-container .form-item::-webkit-input-placeholder, .create-staff-modal-dialog .form-item::-webkit-input-placeholder, .companies .form-item::-webkit-input-placeholder, .modal-body .form-item::-webkit-input-placeholder, #customers .form-item::-webkit-input-placeholder {
    font-size: 13px; }
  .wizard-container .form-item::placeholder,
  .create-staff-modal-dialog .form-item::placeholder,
  .companies .form-item::placeholder,
  .modal-body .form-item::placeholder,
  #customers .form-item::placeholder {
    font-size: 13px; }

.wizard-container .business-details,
.create-staff-modal-dialog .business-details,
.companies .business-details,
.modal-body .business-details,
#customers .business-details {
  width: 80%;
  margin: 10px auto; }
  .wizard-container .business-details.phone-details,
  .create-staff-modal-dialog .business-details.phone-details,
  .companies .business-details.phone-details,
  .modal-body .business-details.phone-details,
  #customers .business-details.phone-details {
    margin-top: 20px !important; }
  .wizard-container .business-details label,
  .create-staff-modal-dialog .business-details label,
  .companies .business-details label,
  .modal-body .business-details label,
  #customers .business-details label {
    text-align: left; }
  .wizard-container .business-details .logo-label,
  .create-staff-modal-dialog .business-details .logo-label,
  .companies .business-details .logo-label,
  .modal-body .business-details .logo-label,
  #customers .business-details .logo-label {
    font-size: 18px; }
  .wizard-container .business-details .selectpicker,
  .create-staff-modal-dialog .business-details .selectpicker,
  .companies .business-details .selectpicker,
  .modal-body .business-details .selectpicker,
  #customers .business-details .selectpicker {
    width: 100%; }
  .wizard-container .business-details .bootstrap-select,
  .create-staff-modal-dialog .business-details .bootstrap-select,
  .companies .business-details .bootstrap-select,
  .modal-body .business-details .bootstrap-select,
  #customers .business-details .bootstrap-select {
    width: 100% !important; }
  .wizard-container .business-details .btn.dropdown-toggle,
  .create-staff-modal-dialog .business-details .btn.dropdown-toggle,
  .companies .business-details .btn.dropdown-toggle,
  .modal-body .business-details .btn.dropdown-toggle,
  #customers .business-details .btn.dropdown-toggle {
    background-color: unset;
    width: 100% !important;
    border-radius: 10px;
    border: 1px solid #ced4da;
    height: 45px;
    box-shadow: unset !important;
    padding-left: 13px !important; }
  .wizard-container .business-details .btn:hover,
  .create-staff-modal-dialog .business-details .btn:hover,
  .companies .business-details .btn:hover,
  .modal-body .business-details .btn:hover,
  #customers .business-details .btn:hover {
    box-shadow: none !important; }
  .wizard-container .business-details .btn:active, .wizard-container .business-details .btn:focus,
  .create-staff-modal-dialog .business-details .btn:active,
  .create-staff-modal-dialog .business-details .btn:focus,
  .companies .business-details .btn:active,
  .companies .business-details .btn:focus,
  .modal-body .business-details .btn:active,
  .modal-body .business-details .btn:focus,
  #customers .business-details .btn:active,
  #customers .business-details .btn:focus {
    outline: none !important;
    box-shadow: none !important; }
  .wizard-container .business-details .form-group .form-item,
  .create-staff-modal-dialog .business-details .form-group .form-item,
  .companies .business-details .form-group .form-item,
  .modal-body .business-details .form-group .form-item,
  #customers .business-details .form-group .form-item {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-radius: 10px;
    height: 40px; }
    .wizard-container .business-details .form-group .form-item:active, .wizard-container .business-details .form-group .form-item:focus,
    .create-staff-modal-dialog .business-details .form-group .form-item:active,
    .create-staff-modal-dialog .business-details .form-group .form-item:focus,
    .companies .business-details .form-group .form-item:active,
    .companies .business-details .form-group .form-item:focus,
    .modal-body .business-details .form-group .form-item:active,
    .modal-body .business-details .form-group .form-item:focus,
    #customers .business-details .form-group .form-item:active,
    #customers .business-details .form-group .form-item:focus {
      outline: none !important;
      border-color: #ced4da !important;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important; }
    .wizard-container .business-details .form-group .form-item::-webkit-input-placeholder, .create-staff-modal-dialog .business-details .form-group .form-item::-webkit-input-placeholder, .companies .business-details .form-group .form-item::-webkit-input-placeholder, .modal-body .business-details .form-group .form-item::-webkit-input-placeholder, #customers .business-details .form-group .form-item::-webkit-input-placeholder {
      font-size: 13px; }
    .wizard-container .business-details .form-group .form-item::placeholder,
    .create-staff-modal-dialog .business-details .form-group .form-item::placeholder,
    .companies .business-details .form-group .form-item::placeholder,
    .modal-body .business-details .form-group .form-item::placeholder,
    #customers .business-details .form-group .form-item::placeholder {
      font-size: 13px; }
  .wizard-container .business-details .form-item-select .css-yk16xz-control,
  .create-staff-modal-dialog .business-details .form-item-select .css-yk16xz-control,
  .companies .business-details .form-item-select .css-yk16xz-control,
  .modal-body .business-details .form-item-select .css-yk16xz-control,
  #customers .business-details .form-item-select .css-yk16xz-control {
    display: flex;
    width: 100%;
    padding: 0 .220rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-radius: 10px;
    height: 40px; }
    .wizard-container .business-details .form-item-select .css-yk16xz-control:active, .wizard-container .business-details .form-item-select .css-yk16xz-control:focus,
    .create-staff-modal-dialog .business-details .form-item-select .css-yk16xz-control:active,
    .create-staff-modal-dialog .business-details .form-item-select .css-yk16xz-control:focus,
    .companies .business-details .form-item-select .css-yk16xz-control:active,
    .companies .business-details .form-item-select .css-yk16xz-control:focus,
    .modal-body .business-details .form-item-select .css-yk16xz-control:active,
    .modal-body .business-details .form-item-select .css-yk16xz-control:focus,
    #customers .business-details .form-item-select .css-yk16xz-control:active,
    #customers .business-details .form-item-select .css-yk16xz-control:focus {
      outline: none !important;
      border-color: #ced4da !important;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important;
      height: 40px !important; }
    .wizard-container .business-details .form-item-select .css-yk16xz-control::-webkit-input-placeholder, .create-staff-modal-dialog .business-details .form-item-select .css-yk16xz-control::-webkit-input-placeholder, .companies .business-details .form-item-select .css-yk16xz-control::-webkit-input-placeholder, .modal-body .business-details .form-item-select .css-yk16xz-control::-webkit-input-placeholder, #customers .business-details .form-item-select .css-yk16xz-control::-webkit-input-placeholder {
      font-size: 13px; }
    .wizard-container .business-details .form-item-select .css-yk16xz-control::placeholder,
    .create-staff-modal-dialog .business-details .form-item-select .css-yk16xz-control::placeholder,
    .companies .business-details .form-item-select .css-yk16xz-control::placeholder,
    .modal-body .business-details .form-item-select .css-yk16xz-control::placeholder,
    #customers .business-details .form-item-select .css-yk16xz-control::placeholder {
      font-size: 13px; }
    .wizard-container .business-details .form-item-select .css-yk16xz-control .css-1hwfws3,
    .create-staff-modal-dialog .business-details .form-item-select .css-yk16xz-control .css-1hwfws3,
    .companies .business-details .form-item-select .css-yk16xz-control .css-1hwfws3,
    .modal-body .business-details .form-item-select .css-yk16xz-control .css-1hwfws3,
    #customers .business-details .form-item-select .css-yk16xz-control .css-1hwfws3 {
      height: 100%;
      padding-top: 0;
      padding-bottom: 0; }
  .wizard-container .business-details .form-item-select .css-1pahdxg-control,
  .create-staff-modal-dialog .business-details .form-item-select .css-1pahdxg-control,
  .companies .business-details .form-item-select .css-1pahdxg-control,
  .modal-body .business-details .form-item-select .css-1pahdxg-control,
  #customers .business-details .form-item-select .css-1pahdxg-control {
    height: 40px;
    padding: 0 .220rem;
    border-radius: 10px;
    border-color: #ced4da !important;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important;
    -webkit-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important;
    -moz-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3) !important; }

.wizard-container .time-zone,
.create-staff-modal-dialog .time-zone,
.companies .time-zone,
.modal-body .time-zone,
#customers .time-zone {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 40px; }

.wizard-container .business-hours,
.create-staff-modal-dialog .business-hours,
.companies .business-hours,
.modal-body .business-hours,
#customers .business-hours {
  width: 80%;
  margin: 20px auto 0; }
  .wizard-container .business-hours .bootstrap-select,
  .create-staff-modal-dialog .business-hours .bootstrap-select,
  .companies .business-hours .bootstrap-select,
  .modal-body .business-hours .bootstrap-select,
  #customers .business-hours .bootstrap-select {
    width: 120px !important; }
    .wizard-container .business-hours .bootstrap-select .dropdown-toggle,
    .create-staff-modal-dialog .business-hours .bootstrap-select .dropdown-toggle,
    .companies .business-hours .bootstrap-select .dropdown-toggle,
    .modal-body .business-hours .bootstrap-select .dropdown-toggle,
    #customers .business-hours .bootstrap-select .dropdown-toggle {
      background-color: unset !important;
      color: #000 !important; }
  .wizard-container .business-hours .day-content,
  .create-staff-modal-dialog .business-hours .day-content,
  .companies .business-hours .day-content,
  .modal-body .business-hours .day-content,
  #customers .business-hours .day-content {
    display: flex;
    align-items: center;
    justify-content: space-around; }
    .wizard-container .business-hours .day-content .hours-select select,
    .create-staff-modal-dialog .business-hours .day-content .hours-select select,
    .companies .business-hours .day-content .hours-select select,
    .modal-body .business-hours .day-content .hours-select select,
    #customers .business-hours .day-content .hours-select select {
      width: 85px;
      height: 30px; }
    .wizard-container .business-hours .day-content .hours-select span,
    .create-staff-modal-dialog .business-hours .day-content .hours-select span,
    .companies .business-hours .day-content .hours-select span,
    .modal-body .business-hours .day-content .hours-select span,
    #customers .business-hours .day-content .hours-select span {
      margin: 0 5px 0 9px; }
    .wizard-container .business-hours .day-content .day-name,
    .create-staff-modal-dialog .business-hours .day-content .day-name,
    .companies .business-hours .day-content .day-name,
    .modal-body .business-hours .day-content .day-name,
    #customers .business-hours .day-content .day-name {
      min-width: 110px !important; }
      .wizard-container .business-hours .day-content .day-name span,
      .create-staff-modal-dialog .business-hours .day-content .day-name span,
      .companies .business-hours .day-content .day-name span,
      .modal-body .business-hours .day-content .day-name span,
      #customers .business-hours .day-content .day-name span {
        font: normal normal normal 16px/25px "Roboto", sans-serif;
        letter-spacing: 0;
        color: #A4A4A4; }

.wizard-container .info-text,
.create-staff-modal-dialog .info-text,
.companies .info-text,
.modal-body .info-text,
#customers .info-text {
  margin: 10px 0 0 !important;
  font-size: 28px; }

.working-schedule .time-zone.working {
  width: 250px; }

#calendar-container .worker-name {
  margin-top: 15px;
  font-size: 2em;
  text-align: center;
  margin-bottom: 0; }

.image-container {
  height: 100vh !important;
  background-position: center center;
  background-size: cover;
  position: absolute;
  width: 100vw !important;
  top: 0;
  left: 0; }
  .image-container .container {
    height: 100%;
    margin-left: auto !important; }
    .image-container .container .row:first-child {
      height: 100%; }

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  font-size: 15px;
  margin-left: 12px;
  -webkit-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.container input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

p.error-message {
  color: #F12B2C;
  font: normal 500 12px/16px Nunito Sans, sans-serif;
  margin-top: 6px;
  margin-bottom: 6px; }

.error-message-content {
  display: flex;
  align-items: center; }
  .error-message-content p {
    margin-left: 8px;
    color: #FF2A2A; }
  .error-message-content svg {
    display: block;
    height: 16px;
    width: 16px; }

.error-message-content:empty {
  height: 25px; }

a:-webkit-any-link {
  text-decoration: none;
  color: #0282DA; }

a:hover {
  text-decoration: none !important; }

.modal.fade.show {
  display: block;
  margin-top: 5%; }

.modal .modal-dialog {
  z-index: 999;
  max-width: 730px;
  text-align: center;
  font-size: 16px;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }
  .modal .modal-dialog .modal-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    justify-content: center; }

.loading .container {
  margin-left: auto !important; }
  .loading .container.full-height {
    height: calc(100vh - 130px); }

.MuiSelect-select:focus {
  background-color: #FFFFFF !important; }

.menu-item.create-appointment,
.menu-item.appointments,
.menu-item.messages,
.menu-item.reminders,
.menu-item.customers,
.menu-item.notifications,
.menu-item.industries,
.menu-item.customers {
  width: 100%;
  background-color: #fff;
  font-family: 'Poppins', sans-serif; }
  .menu-item.create-appointment .general-content,
  .menu-item.appointments .general-content,
  .menu-item.messages .general-content,
  .menu-item.reminders .general-content,
  .menu-item.customers .general-content,
  .menu-item.notifications .general-content,
  .menu-item.industries .general-content,
  .menu-item.customers .general-content {
    font-family: "Roboto", sans-serif;
    color: black;
    width: 100%;
    margin: 0 auto; }
    .menu-item.create-appointment .general-content .page-top-bar,
    .menu-item.appointments .general-content .page-top-bar,
    .menu-item.messages .general-content .page-top-bar,
    .menu-item.reminders .general-content .page-top-bar,
    .menu-item.customers .general-content .page-top-bar,
    .menu-item.notifications .general-content .page-top-bar,
    .menu-item.industries .general-content .page-top-bar,
    .menu-item.customers .general-content .page-top-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 20px 10px 20px;
      width: 100%;
      border-bottom: 1px solid #CBCDCF;
      background: #fff; }
      .menu-item.create-appointment .general-content .page-top-bar h3,
      .menu-item.appointments .general-content .page-top-bar h3,
      .menu-item.messages .general-content .page-top-bar h3,
      .menu-item.reminders .general-content .page-top-bar h3,
      .menu-item.customers .general-content .page-top-bar h3,
      .menu-item.notifications .general-content .page-top-bar h3,
      .menu-item.industries .general-content .page-top-bar h3,
      .menu-item.customers .general-content .page-top-bar h3 {
        font-weight: normal;
        font-size: 22px; }
    .menu-item.create-appointment .general-content .panel,
    .menu-item.appointments .general-content .panel,
    .menu-item.messages .general-content .panel,
    .menu-item.reminders .general-content .panel,
    .menu-item.customers .general-content .panel,
    .menu-item.notifications .general-content .panel,
    .menu-item.industries .general-content .panel,
    .menu-item.customers .general-content .panel {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%; }
      .menu-item.create-appointment .general-content .panel .main-content,
      .menu-item.appointments .general-content .panel .main-content,
      .menu-item.messages .general-content .panel .main-content,
      .menu-item.reminders .general-content .panel .main-content,
      .menu-item.customers .general-content .panel .main-content,
      .menu-item.notifications .general-content .panel .main-content,
      .menu-item.industries .general-content .panel .main-content,
      .menu-item.customers .general-content .panel .main-content {
        width: calc(100% - 80px); }

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 22px; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 1px;
  background-color: white;
  transition: .4s; }

.switch input:checked + .slider {
  background-color: #0282DA;
  opacity: .8; }

.switch input:focus + .slider {
  box-shadow: 0 0 1px #0282DA;
  opacity: .9; }

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px); }

/* Rounded sliders */
.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }

input:disabled {
  cursor: no-drop;
  background-color: #f2f2f2 !important;
  color: #999999 !important; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.invite-customer div:nth-child(1)[aria-hidden="true"], .existing-user div:nth-child(1)[aria-hidden="true"] {
  background-color: rgba(153, 153, 153, 0.32) !important; }

#second-navbar {
  min-height: 64px;
  position: relative; }
  @media all and (max-width: 966px) {
    #second-navbar .title-companyName {
      margin-top: 12px; } }
  #second-navbar .button-box {
    height: 39px;
    display: inline-block; }
    #second-navbar .button-box button {
      height: 100%;
      padding: 4px 16px;
      font: normal 500 14px/19px Nunito Sans, sans-serif; }
  #second-navbar .company-name-box {
    align-self: center; }
  #second-navbar .company-name-long {
    white-space: nowrap;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px; }
    @media all and (max-width: 375px) {
      #second-navbar .company-name-long {
        width: 120px; } }
    @media all and (max-width: 959px) {
      #second-navbar .company-name-long {
        font-size: 16px; } }
  #second-navbar .divider-title {
    border-right: 2px solid #A4A4A4;
    padding-right: 24px;
    margin-right: 24px;
    align-self: center; }
  #second-navbar .appointments-fields {
    display: flex;
    align-items: center; }
    @media all and (max-width: 1024px) {
      #second-navbar .appointments-fields {
        margin-top: 12px;
        margin-bottom: 12px; } }
    #second-navbar .appointments-fields .search-staff,
    #second-navbar .appointments-fields .search-customer {
      margin-right: 20px; }
      #second-navbar .appointments-fields .search-staff svg,
      #second-navbar .appointments-fields .search-customer svg {
        color: #393939; }
  #second-navbar .MuiFormControl-root.search-appointment,
  #second-navbar .MuiFormControl-root.search-customer,
  #second-navbar .MuiFormControl-root.search-staff {
    width: 270px; }
    @media all and (max-width: 552px) {
      #second-navbar .MuiFormControl-root.search-appointment,
      #second-navbar .MuiFormControl-root.search-customer,
      #second-navbar .MuiFormControl-root.search-staff {
        margin-right: 0; } }
    #second-navbar .MuiFormControl-root.search-appointment svg,
    #second-navbar .MuiFormControl-root.search-customer svg,
    #second-navbar .MuiFormControl-root.search-staff svg {
      color: #393939; }
  #second-navbar .filterIcon {
    margin-right: 9px; }
  #second-navbar .buttons-box {
    align-self: center; }

.working-schedule .time-zone,
.breaking-schedule .time-zone {
  text-align: center;
  margin-bottom: 10px; }
  .working-schedule .time-zone span,
  .breaking-schedule .time-zone span {
    font: normal normal normal 16px/25px "Roboto", sans-serif;
    color: #000000; }
  .working-schedule .time-zone .tab-item,
  .breaking-schedule .time-zone .tab-item {
    text-align: center; }

.tab-list .tab-item {
  margin: 0 auto; }

.tab-content {
  margin-top: 50px; }
  @media (max-width: 576px) {
    .tab-content {
      margin-top: 85px; } }

.tabs-top-bar {
  position: fixed !important;
  z-index: 99 !important;
  top: 130px; }
  @media all and (max-width: 1024px) {
    .tabs-top-bar .top-bar-right, .tabs-top-bar .top-bar-right-customer {
      margin: 0 auto 15px auto; } }
  @media (max-width: 576px) {
    .tabs-top-bar {
      top: 214px; } }

.dropdown-toggle::after {
  display: none !important; }

.css-26l3qy-menu {
  z-index: 99999; }

.MuiTabs-root .MuiTab-root {
  margin-right: 15px;
  min-width: unset; }

.MuiPopover-paper {
  max-width: unset !important; }

.customers-customers-line {
  min-height: 222px;
  flex-wrap: wrap;
  border-radius: 5px;
  opacity: 1; }
  .customers-customers-line .col-auto.customer-item {
    margin: 0 !important;
    display: flex;
    padding: 0 20px; }
    .customers-customers-line .col-auto.customer-item .actions-dropdown-customer {
      padding: 0 !important;
      position: relative; }
      .customers-customers-line .col-auto.customer-item .actions-dropdown-customer .image-content {
        margin-top: 26.79px;
        text-align: center; }
        .customers-customers-line .col-auto.customer-item .actions-dropdown-customer .image-content img {
          width: 47%; }
  .customers-customers-line .company-name,
  .customers-customers-line .customer-name,
  .customers-customers-line .staff-name,
  .customers-customers-line .industry-name {
    margin-top: 14px;
    padding: 0 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    font-size: 14px !important; }
    .customers-customers-line .company-name:hover,
    .customers-customers-line .customer-name:hover,
    .customers-customers-line .staff-name:hover,
    .customers-customers-line .industry-name:hover {
      color: #0282DA; }

.custom-card {
  margin-bottom: 20px;
  border: 0.3px solid #A4A4A4;
  width: 150px;
  height: 150px;
  flex-wrap: wrap;
  position: relative;
  background: #FFFFFF;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 1px 3px #000000; }
  .custom-card:hover {
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 1px 3px #000000;
    border: 1px solid #0282DA;
    border-radius: 2px;
    opacity: 1;
    transition: .5s; }
  .custom-card .mask {
    position: absolute;
    top: 6px;
    right: 0;
    display: flex;
    align-items: flex-start;
    justify-content: right;
    border-radius: 5px;
    transition: .3s all ease-in-out;
    opacity: 1; }
    .custom-card .mask button {
      padding: 0; }
    .custom-card .mask:hover svg path {
      fill: #0282DA; }

select.Mui-disabled {
  background: #f2f2f2; }

.select-min-params {
  min-width: 200px;
  height: 42px; }

.alert-title,
.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.box-general {
  padding: 0 10px; }
  .box-general .card-box-img {
    max-width: 140px;
    min-width: 140px;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3) !important; }

.card-box-avatar {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3); }

.menu-items-list {
  padding-right: 8px;
  padding-left: 8px; }
  .menu-items-list .menu-items {
    transition: all 0.5s ease;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 8px; }
    .menu-items-list .menu-items .MuiTypography-displayBlock {
      display: inline-block; }
    .menu-items-list .menu-items:hover {
      background-color: #41A1E3; }
  @media all and (max-width: 600px) {
    .menu-items-list .menu-items {
      border-radius: 0; }
    .menu-items-list {
      padding-right: unset;
      padding-left: unset; } }

.menu-role-item:hover {
  background-color: #4CA0FF !important; }

.sub-menu-item {
  padding-left: 6px;
  background-color: #006ce5; }

.active-menu-item .menu-items {
  background-color: #41A1E3; }

.MuiFormControl-marginDense {
  margin: 0 !important; }

#account-type,
#personal-details,
#business-details {
  background-image: url(https://dev.settime.io/static/media/background.feeff2e7.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  max-height: 100vh; }

.personal-container, .business-container {
  margin: 0 auto !important; }

.primary-btn {
  font: 500 14px/19px "Roboto", sans-serif;
  color: #fff;
  min-width: 120px !important;
  max-width: 120px;
  width: 100%;
  transition: .5s !important; }

.box-cursor {
  cursor: pointer; }

.MuiDialogTitle-root {
  padding: 4px 4px 4px 12px !important; }

.MuiDialogContent-dividers {
  padding: 16px !important; }

.MuiIconButton-root:hover {
  background-color: rgba(60, 64, 67, 0.078) !important; }

#fixed-box, #fixed-box-info {
  margin: unset;
  width: 100%;
  padding: 16px;
  border-radius: 2px; }
  #fixed-box .rect-shape, #fixed-box-info .rect-shape {
    width: 100% !important;
    margin-right: 0 !important; }
  #fixed-box .text-row, #fixed-box-info .text-row {
    margin-top: 0 !important; }

.info-box {
  padding: 16px;
  border: 1px solid #ececec;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12); }
  .info-box .info-box-title {
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500; }
    .info-box .info-box-title .info-title-text {
      font-size: 16px;
      font-weight: 500; }

.title-abbr {
  cursor: pointer !important; }

@media all and (min-width: 500px) {
  .MuiDialog-paperWidthXs,
  .MuiDialog-paperWidthSm {
    min-width: 470px; } }

@media all and (min-width: 500px) {
  .MuiDialog-paperWidthMd {
    min-width: 470px;
    max-width: 500px !important; } }

.MuiButton-containedPrimary:hover {
  background-color: #62B1E8 !important; }

.auth-box {
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
  background-color: #FFFFFF; }

.auth-logo {
  max-width: 200px; }
  .auth-logo img {
    width: 100%; }
    @media all and (max-width: 375px) {
      .auth-logo img {
        max-width: 170px; } }

.login-header {
  padding: 40px 0; }
  @media all and (max-width: 375px) {
    .login-header {
      padding: 20px 0; } }

.auth-bg {
  background-image: url(https://dev.settime.io/static/media/auth-bg.15d0c8cf.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 365px, 365px;
  background-attachment: fixed; }
  @media all and (max-width: 576px) {
    .auth-bg {
      background-image: none; } }

.auth {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1; }

.MuiTab-textColorSecondary.Mui-selected {
  color: #0282DA !important; }

.MuiTabs-root, .MuiTab-root {
  min-height: 0 !important; }

.MuiFormControlLabel-root {
  margin-bottom: 0; }

.selectSize {
  min-width: 120px !important; }

.already-added td {
  vertical-align: middle !important; }

.invite-individual-customer .error-message {
  padding-left: 0;
  padding-top: 5px; }

.elipsis {
  width: 100%; }
  .elipsis p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 75%; }

.elipsis-small p {
  width: 60%; }

.checkbox-accept {
  margin-right: 5px !important;
  margin-top: 8px; }
  .checkbox-accept .MuiTypography-body1 {
    font-size: 10px !important; }

.MuiAlert-standardSuccess {
  background-color: #DBE2DB !important; }

.MuiAlert-standardSuccess .MuiAlert-message {
  color: #2A3D2A; }

.MuiAlert-standardSuccess .MuiAlert-icon {
  color: #9DCC9B !important; }

.MuiSvgIcon-fontSizeSmall {
  color: #2A3D2A; }

.MuiAlert-standardInfo {
  background-color: #DFECF4 !important; }

.MuiAlert-standardInfo .MuiAlert-icon {
  color: #62B1E8 !important; }

.MuiAlert-standardInfo .MuiSvgIcon-fontSizeSmall {
  color: #2E3A43; }

.MuiAlert-standardInfo .MuiAlert-message {
  color: #2E3A43; }

.accept-checkbox {
  font-size: 10px !important; }

.confirm-password {
  margin-bottom: 8px; }

.privacy-txt {
  color: #0282DA; }

.no-appointment {
  color: #A4A4A4; }

.remove-industry-avatar {
  position: absolute;
  right: 0;
  cursor: pointer; }
  .remove-industry-avatar .remove-icon {
    display: none; }

.thumbInner:hover .remove-icon {
  display: block;
  font-size: 30px;
  padding: 5px;
  border-radius: 50%;
  background-color: #cccccc;
  color: #FFFFFF; }

.option-logo {
  margin-right: 15px; }
  .option-logo img {
    vertical-align: middle;
    width: 20px;
    height: 20px; }

.settings-icon {
  vertical-align: middle; }

.MuiDialogActions-root.appointment-bottom-btn {
  display: inline; }
  .MuiDialogActions-root.appointment-bottom-btn .bottom-btn {
    display: flex;
    justify-content: space-between; }

.MuiSwitch-track {
  background-color: #62B1E8 !important; }

.addStaffDivider {
  margin: 10px 0 !important; }

::-webkit-scrollbar {
  width: 7px;
  height: 7px; }

::-webkit-scrollbar-track {
  background: #f1f1f1; }

::-webkit-scrollbar-thumb {
  background: #A4A4A4;
  border-radius: 5px; }

.MuiMenuItem-root.input-menu {
  width: 100% !important; }
  .MuiMenuItem-root.input-menu:hover {
    background: none !important; }
  .MuiMenuItem-root.input-menu .input-field {
    font-size: 12px; }

.input-menu {
  padding: 5px 1px; }

.fc-custom-button {
  background: transparent;
  border: none;
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 25px;
  padding: 0; }
  .fc-custom-button:hover {
    background: transparent;
    border: none; }
  .fc-custom-button:active {
    background: transparent;
    border: none; }

.notes-from-creator {
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  font: normal 400 14px/19px Nunito Sans, sans-serif;
  color: #A4A4A4; }
  .notes-from-creator:focus {
    outline: none; }

.notes-from-appointment {
  min-width: 100%;
  max-width: 100%;
  min-height: 50px;
  max-height: 200px;
  border: 1px solid #c4c4c4;
  border-radius: 4px; }
  .notes-from-appointment:focus {
    outline: none; }

.info-working-schedule-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: #0d3c61;
  background-color: #e8f4fd; }

.card-box-root-service {
  overflow: unset !important; }
  .card-box-root-service.card-plus-box {
    height: unset; }
  .card-box-root-service .content-card-box, .card-box-root-service .card-box-root {
    height: unset; }
  .card-box-root-service .card-box-root .MuiTypography-h5 {
    width: unset; }
  .card-box-root-service .service {
    padding: 12px;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    margin-bottom: 10px; }

.accordion-box {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12); }

.accordion-category .MuiAccordionSummary-content {
  margin: 0; }

.MuiAlert-root.MuiAlert-filledSuccess {
  position: absolute;
  top: 3%;
  right: 1%;
  width: 350px;
  height: 50px;
  padding: 10px;
  z-index: 1500;
  display: flex;
  align-items: center; }
  .MuiAlert-root.MuiAlert-filledSuccess .MuiAlert-icon svg {
    width: 35px;
    height: 35px; }

.duration {
  margin-right: 15px !important; }

.company-name {
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px; }

.companySelect {
  max-width: 220px; }

.companySelectOptions {
  width: 220px !important;
  text-overflow: ellipsis;
  white-space: nowrap !important;
  overflow: hidden !important; }

.industries-list {
  min-width: 240px; }

.appointment-status {
  width: 146px; }
  .appointment-status .MuiSelect-icon {
    color: #eeeeee; }

.profession-label {
  padding-left: 10px;
  font-size: 12px;
  color: #8c949d; }

.closed-days-button {
  margin-top: 10px; }

.service-drawer .MuiFormControlLabel-root {
  margin-left: 0; }
  .service-drawer .MuiFormControlLabel-root .MuiTypography-body1 {
    color: #393939;
    font: normal 400 14px/19px Nunito Sans, sans-serif; }

.service-drawer .MuiAutocomplete-endAdornment {
  top: 6px; }

.service-drawer .MuiInputBase-input.Mui-disabled {
  background-color: #DADADA !important; }

#appointment-menu-item.MuiListItem-gutters {
  padding-left: 0 !important;
  padding-right: 0 !important; }

#appointment-menu-item.MuiMenuItem-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

#appointment-menu-item.MuiListItem-button:hover {
  background-color: transparent !important; }

.MuiInputBase-root.Mui-disabled {
  background-color: #FFFFFF !important; }
  .MuiInputBase-root.Mui-disabled input:disabled {
    background-color: #FFFFFF !important; }

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border: 1px solid #DADADA !important; }

.navbar-tool .MuiInputBase-inputMarginDense {
  padding-bottom: 3px; }

.navbar-tool label + .MuiInput-formControl {
  margin-top: 0 !important; }

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }

.verification-code-input input:not(:placeholder-shown) {
  border-bottom: 2px solid #0282DA; }

.verification-code-input input[value=""] {
  border-bottom: 2px solid #A4A4A4; }

.verification-code-input input[value="N"] {
  border-bottom: 2px solid #A4A4A4; }

.verification-code-input .MuiInputBase-input {
  color: #0282DA;
  font: normal 600 24px/33px Nunito Sans, sans-serif;
  text-align: center; }

#crisp-chatbox {
  z-index: 1299; }
  #crisp-chatbox .cc-dc5e {
    bottom: 60px !important; }

.topBar-items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  grid-gap: 6px;
  gap: 6px; }
  .topBar-items .company-avatar-btn {
    padding: 6px; }
    .topBar-items .company-avatar-btn .topBar-company-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%; }
  .topBar-items .notification-icon-btn {
    padding: 6px; }

.topBar-account-paper.MuiPaper-elevation1 {
  background: #FFFFFF;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.1);
  border-radius: 10px; }

.topBar-account-paper .topBar-account-box {
  padding: 0 !important; }
  .topBar-account-paper .topBar-account-box .detail-menu-item {
    padding: 15px 24px !important; }
    .topBar-account-paper .topBar-account-box .detail-menu-item.MuiListItem-button:hover {
      border-radius: 10px 10px 0 0;
      background-color: unset;
      cursor: default; }
    .topBar-account-paper .topBar-account-box .detail-menu-item .company-name {
      margin: 0;
      font: normal 700 16px/20px Nunito Sans, sans-serif;
      color: #393939; }
    .topBar-account-paper .topBar-account-box .detail-menu-item .company-industry {
      margin: 0;
      font: normal 500 12px/20px Nunito Sans, sans-serif;
      color: #707070; }
  .topBar-account-paper .topBar-account-box .logOut-menu-item {
    border-top: 1px solid #E9E9E9;
    padding: 15px 24px !important; }
    .topBar-account-paper .topBar-account-box .logOut-menu-item.MuiListItem-button:hover {
      border-radius: 0 0 10px 10px; }

.MuiPickersBasePicker-pickerView * {
  text-transform: capitalize; }

#appointment-preview .modal-content {
  display: flex;
  padding: 0 20px;
  border: none;
  border-radius: 0; }
  #appointment-preview .modal-content .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    border-right: 1px solid #ced4da;
    margin: 20px 0; }
    #appointment-preview .modal-content .left .day {
      color: black;
      font-size: 18px;
      margin: 10px 0; }
    #appointment-preview .modal-content .left .month {
      text-transform: capitalize; }
    #appointment-preview .modal-content .left, #appointment-preview .modal-content .left .week {
      color: gray;
      font-size: 14px; }
  #appointment-preview .modal-content .right {
    width: 100%;
    padding: 30px 0;
    margin: 0 20px; }
    #appointment-preview .modal-content .right .top {
      border-bottom: 1px solid #ced4da; }
    #appointment-preview .modal-content .right .top, #appointment-preview .modal-content .right .bottom {
      width: 100%;
      display: flex;
      padding: 20px; }
      #appointment-preview .modal-content .right .top .info-block:nth-child(1), #appointment-preview .modal-content .right .bottom .info-block:nth-child(1) {
        min-width: 168px; }
      #appointment-preview .modal-content .right .top div.info-block .title, #appointment-preview .modal-content .right .bottom div.info-block .title {
        color: gray; }
      #appointment-preview .modal-content .right .top div.info-block .value, #appointment-preview .modal-content .right .bottom div.info-block .value {
        font-weight: 600; }

.industry-logo {
  margin-right: 10px; }

.filter-hours {
  display: flex;
  justify-content: center;
  align-items: center; }
  .filter-hours .hours-select .MuiInputBase-root {
    width: 100%; }

.active-title {
  display: flex;
  justify-content: space-between;
  padding: 19px 20px 19px 20px;
  background: #fff;
  z-index: 100;
  width: calc(100% - 250px);
  left: 250px; }
  @media (max-width: 767px) {
    .active-title {
      width: 100%;
      left: 0; } }

#outlined-textarea {
  min-height: 128px;
  max-height: 128px; }

.line-customers {
  background-color: #FFFFFF; }

.customers .main-content {
  min-height: 0 !important;
  height: 100% !important; }

.working-hours, .breaking-hours {
  border: 1px solid #CBCDCF;
  padding: 17px 22px 3px 17px;
  overflow: auto; }

.title-hours {
  margin-bottom: 45px;
  text-align: center; }
  @media (max-width: 1350px) {
    .title-hours {
      margin: 45px auto 45px auto; } }
  .title-hours p {
    font: 500 16px/25px "Roboto", sans-serif !important;
    color: #000000 !important; }

.day-name span {
  font: 400 16px/25px "Roboto", sans-serif;
  color: #000000; }

.hours-select {
  display: flex;
  justify-content: center; }
  .hours-select .border-to {
    border-top: 1px solid #CBCDCF;
    width: 10px;
    align-self: center;
    margin-right: 4px !important;
    margin-left: 4px !important; }

.tab-list {
  border-radius: 5px;
  height: 100%; }
  .tab-list .list-tabs {
    display: flex;
    justify-content: center;
    margin: 0; }
    @media all and (max-width: 1350px) {
      .tab-list .list-tabs {
        flex-direction: column;
        padding-bottom: 45px; } }
  .tab-list .item-working {
    max-width: 408px;
    width: 100%;
    overflow: scroll !important; }
    @media all and (max-width: 1350px) {
      .tab-list .item-working {
        margin: 0 auto; } }
    .tab-list .item-working .working-hours {
      min-width: 408px; }
  .tab-list .week-day-switch {
    margin: 0;
    align-self: center; }
  .tab-list .tab-item {
    justify-content: center;
    border-radius: 4px;
    overflow: auto; }
    .tab-list .tab-item .business-hours {
      margin: 0 auto 0 !important;
      width: 100%; }

.day-content {
  justify-content: center; }
  .day-content .hours-select {
    height: 30px; }
    .day-content .hours-select span {
      margin: 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #CBCDCF;
      font-size: 20px; }

.selects-border {
  border-bottom: 1px solid #A4A4A4;
  width: 50%;
  margin: 0 auto; }

.day-content {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 30px; }

.industry-logo {
  margin-right: 10px; }

.filter-hours {
  display: flex;
  justify-content: center;
  align-items: center; }
  .filter-hours .hours-select .MuiInputBase-root {
    width: 100%; }

#reminders .dropdown-toggle {
  width: 256px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 16px;
  box-shadow: 0 3px 6px #000000;
  border-radius: 16px;
  background: #0282DA;
  border: none; }

#reminders .appointments-reminder-content {
  padding: 40px 0; }
  #reminders .appointments-reminder-content .reminder-block {
    position: relative;
    margin-bottom: 65px; }
    #reminders .appointments-reminder-content .reminder-block .reminder-bg {
      height: calc(100% + 30px);
      align-items: center;
      position: absolute;
      top: 0;
      left: 40px; }
      #reminders .appointments-reminder-content .reminder-block .reminder-bg .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #0282DA; }
      #reminders .appointments-reminder-content .reminder-block .reminder-bg .line {
        height: calc(100% - 20px);
        width: 1px;
        background-color: #0282DA; }
    #reminders .appointments-reminder-content .reminder-block .title {
      color: #0282DA;
      font-size: 16px;
      margin-bottom: 10px;
      margin-left: 60px; }
    #reminders .appointments-reminder-content .reminder-block .reminder-item {
      box-shadow: 0 3px 6px #000000;
      height: 78px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 16px;
      line-height: 25px;
      color: #A8AABC;
      margin-bottom: 15px;
      position: relative;
      background-color: #fff;
      z-index: 2; }
      #reminders .appointments-reminder-content .reminder-block .reminder-item.checked {
        color: #0282DA; }
      #reminders .appointments-reminder-content .reminder-block .reminder-item .actions span {
        color: #A8AABC;
        font-size: 24px; }

#reminders .reminder-modals .modal-dialog {
  max-width: 60%; }

#reminders .reminder-modals .modal-content {
  border-radius: 16px;
  padding: 50px 40px; }
  #reminders .reminder-modals .modal-content .modal-title {
    font-size: 28px;
    color: #0282DA;
    margin-bottom: 35px;
    font-weight: 500;
    text-align: left; }
  #reminders .reminder-modals .modal-content .modal-body .form-group {
    margin-bottom: 25px; }
    #reminders .reminder-modals .modal-content .modal-body .form-group label {
      color: #0282DA;
      margin-bottom: 10px;
      font-size: 16px; }
    #reminders .reminder-modals .modal-content .modal-body .form-group .form-item, #reminders .reminder-modals .modal-content .modal-body .form-group .form-select {
      box-shadow: 0 3px 6px #000000;
      border-radius: 16px;
      height: 42px;
      border: none; }
      #reminders .reminder-modals .modal-content .modal-body .form-group .form-item .css-yk16xz-control,
      #reminders .reminder-modals .modal-content .modal-body .form-group .form-item .css-1pahdxg-control, #reminders .reminder-modals .modal-content .modal-body .form-group .form-select .css-yk16xz-control,
      #reminders .reminder-modals .modal-content .modal-body .form-group .form-select .css-1pahdxg-control {
        height: 100%;
        border: none;
        border-radius: inherit; }
    #reminders .reminder-modals .modal-content .modal-body .form-group textarea.form-item {
      height: 144px;
      padding-top: 10px;
      resize: none; }

#reminders .reminder-modals.modal.fade.show {
  margin-top: unset; }

.menu-item.create-appointment {
  width: 100%;
  background: #eee;
  font-family: 'Poppins', sans-serif; }
  .menu-item.create-appointment .general-content .panel .main-content .loading-container {
    display: flex;
    align-items: center;
    height: 75%;
    justify-content: center; }
  .menu-item.create-appointment .general-content .panel .main-content .nav-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px; }
    .menu-item.create-appointment .general-content .panel .main-content .nav-content .navigation-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 16%;
      cursor: pointer;
      padding: 20px 0 0 0; }
      .menu-item.create-appointment .general-content .panel .main-content .nav-content .navigation-item.active {
        border-bottom: 3px solid #0282DA; }
        .menu-item.create-appointment .general-content .panel .main-content .nav-content .navigation-item.active .item-count {
          transition: background 0.5s ease;
          background: #0282DA;
          color: #fff; }
        .menu-item.create-appointment .general-content .panel .main-content .nav-content .navigation-item.active .item-name {
          transition: color 0.5s ease;
          font-size: 20px;
          color: #0282DA; }
      .menu-item.create-appointment .general-content .panel .main-content .nav-content .navigation-item.passed {
        border-bottom: 3px solid #0282DA; }
        .menu-item.create-appointment .general-content .panel .main-content .nav-content .navigation-item.passed .item-count {
          background: #fff;
          color: #0282DA; }
        .menu-item.create-appointment .general-content .panel .main-content .nav-content .navigation-item.passed .item-name {
          margin-top: 15px;
          color: #0282DA; }
      .menu-item.create-appointment .general-content .panel .main-content .nav-content .navigation-item .item-count {
        background: #fff;
        color: #a8aabc;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2); }
        .menu-item.create-appointment .general-content .panel .main-content .nav-content .navigation-item .item-count.first {
          padding-right: 1px; }
      .menu-item.create-appointment .general-content .panel .main-content .nav-content .navigation-item .item-name {
        margin-top: 10px;
        color: #a8aabc;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 80%;
        overflow: hidden; }
    .menu-item.create-appointment .general-content .panel .main-content .nav-content .navigation-item-individual {
      width: 22%; }
    .menu-item.create-appointment .general-content .panel .main-content .nav-content .circle-container {
      width: 12%;
      display: flex;
      justify-content: center;
      padding: 37px 0; }
      .menu-item.create-appointment .general-content .panel .main-content .nav-content .circle-container.active {
        border-bottom: 3px solid #0282DA; }
        .menu-item.create-appointment .general-content .panel .main-content .nav-content .circle-container.active .circle-separator {
          background: #0282DA; }
      .menu-item.create-appointment .general-content .panel .main-content .nav-content .circle-container .circle-separator {
        width: 20px;
        height: 20px;
        background: #b6b8c7;
        border: 1px solid #b6b8c7;
        border-radius: 10px;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2); }
    .menu-item.create-appointment .general-content .panel .main-content .nav-content .circle-container-individual {
      width: 17%; }
  .menu-item.create-appointment .general-content .panel .main-content .industries-tab .industries-content {
    margin-top: 25px; }
    .menu-item.create-appointment .general-content .panel .main-content .industries-tab .industries-content .industry-item-container {
      display: flex;
      flex-direction: column; }
      .menu-item.create-appointment .general-content .panel .main-content .industries-tab .industries-content .industry-item-container .industry-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 42px;
        padding: 0 20px;
        margin-bottom: 25px;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        cursor: pointer; }
        .menu-item.create-appointment .general-content .panel .main-content .industries-tab .industries-content .industry-item-container .industry-item .industry-name {
          font-size: 18px;
          margin-left: 20px; }
      .menu-item.create-appointment .general-content .panel .main-content .industries-tab .industries-content .industry-item-container .industry-services-content {
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        .menu-item.create-appointment .general-content .panel .main-content .industries-tab .industries-content .industry-item-container .industry-services-content .service-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 96%;
          height: 42px;
          padding: 0 20px;
          margin-bottom: 25px;
          box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
          border-radius: 20px;
          cursor: pointer; }
          .menu-item.create-appointment .general-content .panel .main-content .industries-tab .industries-content .industry-item-container .industry-services-content .service-item .service-name {
            font-size: 18px;
            margin-left: 20px; }
          .menu-item.create-appointment .general-content .panel .main-content .industries-tab .industries-content .industry-item-container .industry-services-content .service-item .image-content img {
            width: 20px; }
  .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content {
    margin-top: 25px; }
    .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container {
      position: relative; }
      .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 42px;
        padding: 0 20px;
        margin-bottom: 25px;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        cursor: pointer; }
        .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-item .logo-content img {
          width: 25px;
          border-radius: 20px; }
        .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-item .item-name {
          font-size: 18px;
          margin-left: 20px; }
      .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details {
        transition: 1s all ease;
        margin-top: 18px; }
        .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .header {
          align-items: center;
          margin: 0 0 42px 0; }
          .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .header .image-content img {
            width: 90px;
            border-radius: 50px; }
          .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .header .name-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 16px; }
            .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .header .name-content h3 {
              align-self: flex-start; }
            .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .header .name-content p {
              color: #959595; }
        .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content {
          margin-bottom: 42px; }
          .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item {
            padding: 0 20px; }
            .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item:first-child {
              padding-left: 0;
              padding-right: 40px; }
            .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item:last-child {
              display: flex;
              flex-direction: column;
              padding-left: 40px;
              padding-right: 0; }
              .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item:last-child .contact-info-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 36px;
                height: 100%;
                justify-content: space-between; }
            .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item .title-container {
              display: flex;
              justify-content: center;
              border-bottom: 1px solid #d4d4d4; }
              .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item .title-container h5 {
                font-weight: 500; }
            .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item .contact-details-content {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center; }
              .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item .contact-details-content .nav-headers {
                display: flex;
                flex-direction: column;
                padding: 0 40px;
                color: #0282DA; }
                .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item .contact-details-content .nav-headers span:not(:first-child) {
                  padding: 10px 0; }
                .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item .contact-details-content .nav-headers span:first-child {
                  padding-bottom: 10px; }
              .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item .contact-details-content .nav-values {
                display: flex;
                flex-direction: column; }
                .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item .contact-details-content .nav-values span:not(:first-child) {
                  padding: 10px 0; }
                .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item .contact-details-content .nav-values span:first-child {
                  padding-bottom: 10px; }
            .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item .details-content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-top: 36px; }
              .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item .details-content .gmap_canvas {
                padding-top: 10px; }
              .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item .details-content .working-hour-item {
                display: flex;
                align-items: center;
                width: 60%;
                justify-content: space-between; }
                .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item .details-content .working-hour-item .week-day {
                  width: 110px;
                  margin: 10px 0;
                  padding: 5px 10px;
                  border-radius: 17px;
                  display: flex;
                  justify-content: center;
                  font-size: 14px;
                  box-shadow: 0 1px 7px 1px rgba(0, 0, 0, 0.2); }
                  .menu-item.create-appointment .general-content .panel .main-content .companies-tab .companies-content .company-item-container .company-details .content .content-item .details-content .working-hour-item .week-day.today {
                    background-color: #0282DA;
                    color: white; }
  .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content,
  .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content,
  .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content,
  .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content {
    padding: 36px 0;
    border-top: 2px solid #bebebe; }
    .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content:first-child,
    .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content:first-child,
    .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content:first-child,
    .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content:first-child {
      margin-top: 44px; }
    .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-item,
    .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-item,
    .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-item,
    .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer; }
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-item .left-content .image-content img,
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-item .left-content .image-content img,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-item .left-content .image-content img,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-item .left-content .image-content img {
        width: 80px;
        border-radius: 40px; }
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-item .left-content .info-content,
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-item .left-content .info-content,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-item .left-content .info-content,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-item .left-content .info-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 16px; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-item .left-content .info-content h3,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-item .left-content .info-content h3,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-item .left-content .info-content h3,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-item .left-content .info-content h3 {
          font-weight: 400;
          align-self: start; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-item .left-content .info-content p,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-item .left-content .info-content p,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-item .left-content .info-content p,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-item .left-content .info-content p {
          color: #959595;
          align-self: start; }
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-item .right-content .first-available-time,
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-item .right-content .first-available-time,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-item .right-content .first-available-time,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-item .right-content .first-available-time {
        font-size: 28px; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-item .right-content .first-available-time span,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-item .right-content .first-available-time span,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-item .right-content .first-available-time span,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-item .right-content .first-available-time span {
          margin-left: 15px; }
    .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details,
    .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details,
    .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details,
    .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details,
    .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details,
    .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details,
    .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details,
    .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details {
      margin-top: 65px;
      padding-bottom: 50px; }
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .month-content .react-calendar,
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .month-content .react-calendar,
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .month-content .react-calendar,
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .month-content .react-calendar,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .month-content .react-calendar,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .month-content .react-calendar,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .month-content .react-calendar,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .month-content .react-calendar {
        border: none;
        font-family: "Poppins", sans-serif; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__navigation,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__navigation,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__navigation,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__navigation,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__navigation,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__navigation,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__navigation,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__navigation {
          align-items: center; }
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow {
            min-width: 30px;
            height: 30px;
            border-radius: 50%;
            background: #fff;
            box-shadow: 0 6px 16px #000000; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__navigation__prev2-button,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__navigation__next2-button,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__prev2-button,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__next2-button,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__navigation__prev2-button,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__navigation__next2-button,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__prev2-button,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__next2-button,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__navigation__prev2-button,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__navigation__next2-button,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__prev2-button,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__next2-button,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__navigation__prev2-button,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__navigation__next2-button,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__prev2-button,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__next2-button {
          display: none; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__navigation__label,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__label,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__navigation__label,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__label,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__navigation__label,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__label,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__navigation__label,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__label {
          border: none;
          color: #0282DA;
          font-family: Poppins, sans-serif;
          font-size: 24px;
          width: auto; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday {
          text-transform: none;
          max-width: 40px !important;
          margin: 0 6px 6px 0; }
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday abbr,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday abbr,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday abbr,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday abbr,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday abbr,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday abbr,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday abbr,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__weekdays__weekday abbr {
            font-size: 14px;
            text-decoration: none;
            font-weight: normal;
            color: #000; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__month-view__days__day,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__days__day,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__month-view__days__day,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__days__day,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__month-view__days__day,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__days__day,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__month-view__days__day,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__days__day {
          font-size: 14px;
          border-radius: 10px;
          max-width: 40px !important;
          height: 40px;
          padding: 0;
          margin: 0 6px 6px 0;
          box-shadow: 0 3px 3px #000000;
          color: #0282DA; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__month-view__days__day--weekend,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__days__day--weekend,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__month-view__days__day--weekend,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__days__day--weekend,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__month-view__days__day--weekend,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__days__day--weekend,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__month-view__days__day--weekend,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__month-view__days__day--weekend {
          background-color: unset;
          color: #AFAFAF80;
          box-shadow: unset; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__tile--active,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__tile--active,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__tile--active,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__tile--active,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__tile--active,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__tile--active,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__tile--active,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__tile--active {
          background-color: #0282DA;
          color: #fff; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__navigation__label,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__label,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__navigation__label,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__label,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .month-content .react-calendar .react-calendar__navigation__label,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__label,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .month-content .react-calendar .react-calendar__navigation__label,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .month-content .react-calendar .react-calendar__navigation__label {
          outline: none;
          background-color: unset; }
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time,
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time,
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time,
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time {
        width: 90%; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time img,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time img,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time img,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time img,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time img,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time img,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time img,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time img {
          margin-bottom: 17px; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time .hours-content,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time .hours-content,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time .hours-content,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time .hours-content,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time .hours-content,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time .hours-content,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time .hours-content,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time .hours-content {
          max-width: 295px; }
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time .hours-content .item,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time .hours-content .item,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time .hours-content .item,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time .hours-content .item,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time .hours-content .item,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time .hours-content .item,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time .hours-content .item,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time .hours-content .item {
            cursor: pointer;
            width: 60px;
            height: 35px;
            border-radius: 16px;
            box-shadow: 0 3px 6px #000000;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            display: inline-flex;
            margin: 10px 17px; }
            .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time .hours-content .item.chosen,
            .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time .hours-content .item.chosen,
            .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time .hours-content .item.chosen,
            .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time .hours-content .item.chosen,
            .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time .hours-content .item.chosen,
            .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time .hours-content .item.chosen,
            .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time .hours-content .item.chosen,
            .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time .hours-content .item.chosen {
              background-color: #0282DA;
              color: #fff; }
            .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time .hours-content .item.disabled:not(.chosen),
            .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time .hours-content .item.disabled:not(.chosen),
            .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time .hours-content .item.disabled:not(.chosen),
            .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time .hours-content .item.disabled:not(.chosen),
            .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time .hours-content .item.disabled:not(.chosen),
            .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time .hours-content .item.disabled:not(.chosen),
            .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time .hours-content .item.disabled:not(.chosen),
            .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time .hours-content .item.disabled:not(.chosen) {
              background: #f0f0f0;
              cursor: no-drop; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time .carousel-item,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time .carousel-item,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time .carousel-item,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time .carousel-item,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time .carousel-item,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time .carousel-item,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time .carousel-item,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time .carousel-item {
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: -webkit-transform .3s ease;
          transition: transform .3s ease;
          transition: transform .3s ease, -webkit-transform .3s ease; }
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time .carousel-item.active,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time .carousel-item.active,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time .carousel-item.active,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time .carousel-item.active,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time .carousel-item.active,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time .carousel-item.active,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time .carousel-item.active,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time .carousel-item.active {
            opacity: 1;
            display: flex; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time .carousel-inner,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time .carousel-inner,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time .carousel-inner,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time .carousel-inner,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time .carousel-inner,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time .carousel-inner,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time .carousel-inner,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time .carousel-inner {
          display: flex;
          min-height: 118px;
          min-width: 250px;
          width: 75%;
          margin: 0 auto; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time .carousel-indicators,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time .carousel-indicators,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time .carousel-indicators,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time .carousel-indicators,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time .carousel-indicators,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time .carousel-indicators,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time .carousel-indicators,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time .carousel-indicators {
          display: none; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time .carousel-control-next,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time .carousel-control-prev,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time .carousel-control-next,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time .carousel-control-prev,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time .carousel-control-next,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time .carousel-control-prev,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time .carousel-control-next,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time .carousel-control-prev,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time .carousel-control-next,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time .carousel-control-prev,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time .carousel-control-next,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time .carousel-control-prev,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time .carousel-control-next,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time .carousel-control-prev,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time .carousel-control-next,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time .carousel-control-prev {
          top: 37%;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #fff;
          box-shadow: 0 6px 16px #000000; }
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time .carousel-control-next .carousel-control-prev-icon,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time .carousel-control-prev .carousel-control-prev-icon,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time .carousel-control-next .carousel-control-prev-icon,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time .carousel-control-prev .carousel-control-prev-icon,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time .carousel-control-next .carousel-control-prev-icon,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time .carousel-control-prev .carousel-control-prev-icon,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time .carousel-control-next .carousel-control-prev-icon,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time .carousel-control-prev .carousel-control-prev-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time .carousel-control-next .carousel-control-prev-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time .carousel-control-prev .carousel-control-prev-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time .carousel-control-next .carousel-control-prev-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time .carousel-control-prev .carousel-control-prev-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time .carousel-control-next .carousel-control-prev-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time .carousel-control-prev .carousel-control-prev-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time .carousel-control-next .carousel-control-prev-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time .carousel-control-prev .carousel-control-prev-icon {
            margin-top: 2px;
            margin-right: 3px;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 10'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time .carousel-control-next .carousel-control-next-icon,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .set-time .carousel-control-prev .carousel-control-next-icon,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time .carousel-control-next .carousel-control-next-icon,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .set-time .carousel-control-prev .carousel-control-next-icon,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time .carousel-control-next .carousel-control-next-icon,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .set-time .carousel-control-prev .carousel-control-next-icon,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time .carousel-control-next .carousel-control-next-icon,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .set-time .carousel-control-prev .carousel-control-next-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time .carousel-control-next .carousel-control-next-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .set-time .carousel-control-prev .carousel-control-next-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time .carousel-control-next .carousel-control-next-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .set-time .carousel-control-prev .carousel-control-next-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time .carousel-control-next .carousel-control-next-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .set-time .carousel-control-prev .carousel-control-next-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time .carousel-control-next .carousel-control-next-icon,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .set-time .carousel-control-prev .carousel-control-next-icon {
            margin-top: 2px;
            margin-left: 3px;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 10'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .notes-content,
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .notes-content,
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .notes-content,
      .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .notes-content,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .notes-content,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .notes-content,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .notes-content,
      .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .notes-content {
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .notes-content.button-added,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .notes-content.button-added,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .notes-content.button-added,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .notes-content.button-added,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .notes-content.button-added,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .notes-content.button-added,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .notes-content.button-added,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .notes-content.button-added {
          justify-content: space-between;
          height: 100%; }
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .notes-content.button-added .area,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .notes-content.button-added .area,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .notes-content.button-added .area,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .notes-content.button-added .area,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .notes-content.button-added .area,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .notes-content.button-added .area,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .notes-content.button-added .area,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .notes-content.button-added .area {
            padding-bottom: 60px;
            margin-top: 0; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .notes-content .title-content,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .notes-content .title-content,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .notes-content .title-content,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .notes-content .title-content,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .notes-content .title-content,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .notes-content .title-content,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .notes-content .title-content,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .notes-content .title-content {
          display: flex;
          justify-content: center;
          width: 80%;
          border-bottom: 2px solid #d4d4d4; }
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .notes-content .title-content .name,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .notes-content .title-content .name,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .notes-content .title-content .name,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .notes-content .title-content .name,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .notes-content .title-content .name,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .notes-content .title-content .name,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .notes-content .title-content .name,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .notes-content .title-content .name {
            font-size: 24px;
            margin-bottom: 9px; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .notes-content .area,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .notes-content .area,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .notes-content .area,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .notes-content .area,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .notes-content .area,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .notes-content .area,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .notes-content .area,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .notes-content .area {
          margin-top: 35px;
          width: 80%; }
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .notes-content .area textarea,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .notes-content .area textarea,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .notes-content .area textarea,
          .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .notes-content .area textarea,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .notes-content .area textarea,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .notes-content .area textarea,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .notes-content .area textarea,
          .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .notes-content .area textarea {
            width: 100%;
            resize: none;
            padding-left: 16px;
            padding-top: 10px;
            border: none;
            background: #FFFFFF 0 0 no-repeat padding-box;
            box-shadow: 0 3px 6px #000000;
            border-radius: 16px; }
            .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .notes-content .area textarea:focus,
            .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .notes-content .area textarea:focus,
            .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .notes-content .area textarea:focus,
            .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .notes-content .area textarea:focus,
            .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .notes-content .area textarea:focus,
            .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .notes-content .area textarea:focus,
            .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .notes-content .area textarea:focus,
            .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .notes-content .area textarea:focus {
              outline: none; }
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .staff-details .notes-content .make-appointment,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .staff-content .individual-user-details .notes-content .make-appointment,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .staff-details .notes-content .make-appointment,
        .menu-item.create-appointment .general-content .panel .main-content .staff-tab .individual-user-content .individual-user-details .notes-content .make-appointment,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .staff-details .notes-content .make-appointment,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .staff-content .individual-user-details .notes-content .make-appointment,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .staff-details .notes-content .make-appointment,
        .menu-item.create-appointment .general-content .panel .main-content .individual-users-tab .individual-user-content .individual-user-details .notes-content .make-appointment {
          align-self: flex-end; }

#details .tab-list #info .react-datepicker-wrapper {
  width: 100%; }

#details #history .message {
  font-size: 24px; }

#customers .customers-line .customer-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 55px 30px;
  box-shadow: 0 3px 6px #000000;
  border: 1px solid #7070703B;
  border-radius: 16px;
  background-color: #fff; }
  #customers .customers-line .customer-item .image-content {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px; }
    #customers .customers-line .customer-item .image-content img {
      min-width: 100%;
      width: 100%;
      height: 100%; }
  #customers .customers-line .customer-item .customer-name {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: #0282DA; }
  #customers .customers-line .customer-item:last-child {
    margin-right: 0; }

.customer-item {
  margin-left: 30px !important; }

.menu-item.appointments.calendar .panel {
  max-width: 1710px;
  width: 100%;
  margin: 0 auto; }

.calendar.menu-item .panel, .calendar.menu-item #calendar-container {
  max-width: 1710px;
  width: 100%;
  margin: 0 auto; }

.menu-item.general {
  width: 100%;
  margin: 0 auto; }
  .menu-item.general .panel {
    max-width: 100%;
    width: 100%;
    margin: 0 auto; }
    .menu-item.general .panel .full-calendar .fc-toolbar {
      display: block;
      position: relative; }
      .menu-item.general .panel .full-calendar .fc-toolbar .fc-left {
        align-items: center; }
        .menu-item.general .panel .full-calendar .fc-toolbar .fc-left h2 {
          font-size: 32px;
          font-weight: bold;
          margin-left: 0; }
        .menu-item.general .panel .full-calendar .fc-toolbar .fc-left .fc-today-button {
          border: 0;
          background: #0282DA;
          border-radius: 8px;
          margin: 5px;
          padding: 10px 20px;
          font-size: 18px; }
        .menu-item.general .panel .full-calendar .fc-toolbar .fc-left button.fc-today-button {
          position: absolute;
          left: 0;
          top: 0; }
        .menu-item.general .panel .full-calendar .fc-toolbar .fc-left button {
          box-shadow: none;
          margin: 10px;
          border-radius: 20px;
          padding: 5px;
          font-weight: bold;
          font-size: 20px; }
          .menu-item.general .panel .full-calendar .fc-toolbar .fc-left button:hover {
            background-color: rgba(60, 64, 67, 0.078); }
      .menu-item.general .panel .full-calendar .fc-toolbar .fc-left, .menu-item.general .panel .full-calendar .fc-toolbar .fc-right {
        display: flex;
        justify-content: center; }
    .menu-item.general .panel .full-calendar .fc-unthemed td.fc-today {
      background: none; }
    .menu-item.general .panel .full-calendar .fc-widget-header table thead tr th.fc-today .day-number {
      color: #fff !important;
      background: #0282DA; }
    .menu-item.general .panel .full-calendar .fc-widget-header table thead tr th .week-day-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px; }
      .menu-item.general .panel .full-calendar .fc-widget-header table thead tr th .week-day-info .day-name {
        font-weight: 500; }
      .menu-item.general .panel .full-calendar .fc-widget-header table thead tr th .week-day-info .day-number {
        width: 80%;
        font-size: 20px;
        font-weight: 400;
        color: #0282DA;
        border-radius: 7px;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        padding: 5px 0; }
    .menu-item.general .panel .full-calendar .fc-body tr:first-child td:first-child[class="fc-widget-content"] {
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
      box-shadow: 0 3px 5px #000000; }
      .menu-item.general .panel .full-calendar .fc-body tr:first-child td:first-child[class="fc-widget-content"] .fc-time-grid .fc-slats table tbody .fc-axis.fc-time {
        text-align: left; }
      .menu-item.general .panel .full-calendar .fc-body tr:first-child td:first-child[class="fc-widget-content"] .fc-time-grid .fc-slats table tbody .fc-minor .fc-widget-content {
        border-top: 0; }
      .menu-item.general .panel .full-calendar .fc-body tr:first-child td:first-child[class="fc-widget-content"] .fc-time-grid .fc-content-skeleton .fc-content-col .fc-event-container {
        width: 100%; }
        .menu-item.general .panel .full-calendar .fc-body tr:first-child td:first-child[class="fc-widget-content"] .fc-time-grid .fc-content-skeleton .fc-content-col .fc-event-container .fc-time-grid-event {
          cursor: pointer;
          border-radius: 5px;
          white-space: nowrap;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex; }
          .menu-item.general .panel .full-calendar .fc-body tr:first-child td:first-child[class="fc-widget-content"] .fc-time-grid .fc-content-skeleton .fc-content-col .fc-event-container .fc-time-grid-event .fc-content {
            display: flex;
            align-items: center; }
          .menu-item.general .panel .full-calendar .fc-body tr:first-child td:first-child[class="fc-widget-content"] .fc-time-grid .fc-content-skeleton .fc-content-col .fc-event-container .fc-time-grid-event .status-color {
            width: 20px;
            height: 20px;
            margin-right: 18px;
            background-color: red; }
            .menu-item.general .panel .full-calendar .fc-body tr:first-child td:first-child[class="fc-widget-content"] .fc-time-grid .fc-content-skeleton .fc-content-col .fc-event-container .fc-time-grid-event .status-color.pending {
              background-color: #F3B175; }
            .menu-item.general .panel .full-calendar .fc-body tr:first-child td:first-child[class="fc-widget-content"] .fc-time-grid .fc-content-skeleton .fc-content-col .fc-event-container .fc-time-grid-event .status-color.finished {
              background-color: #9DCC9B; }
            .menu-item.general .panel .full-calendar .fc-body tr:first-child td:first-child[class="fc-widget-content"] .fc-time-grid .fc-content-skeleton .fc-content-col .fc-event-container .fc-time-grid-event .status-color.canceled {
              background-color: #FF2A2A; }
            .menu-item.general .panel .full-calendar .fc-body tr:first-child td:first-child[class="fc-widget-content"] .fc-time-grid .fc-content-skeleton .fc-content-col .fc-event-container .fc-time-grid-event .status-color.accepted {
              background-color: #0282DA; }
    .menu-item.general .panel .full-calendar .fc-view-container {
      max-height: calc(100vh - 310px);
      overflow-y: auto; }
      .menu-item.general .panel .full-calendar .fc-view-container .fc-view table thead tr td.fc-head-container {
        border: 0;
        box-shadow: 0px -3px 5px #000000; }

#appointment-details {
  display: none;
  z-index: 100;
  position: absolute;
  width: 364px;
  height: 230px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 6px #000000; }
  #appointment-details .details-content {
    height: 100%;
    padding: 5px; }
    #appointment-details .details-content .general-content {
      align-items: center;
      justify-content: center;
      height: 60%;
      position: relative; }
      #appointment-details .details-content .general-content .remove-content {
        position: absolute;
        top: -11px;
        right: 0;
        cursor: pointer; }
      #appointment-details .details-content .general-content .left-content {
        justify-content: space-between; }
      #appointment-details .details-content .general-content .left-content, #appointment-details .details-content .general-content .right-content {
        display: flex;
        flex-direction: column;
        top: 5%;
        bottom: 0;
        height: 80%; }
        #appointment-details .details-content .general-content .left-content .col-item, #appointment-details .details-content .general-content .right-content .col-item {
          display: flex;
          justify-content: space-between;
          align-items: center; }
          #appointment-details .details-content .general-content .left-content .col-item span.name-content, #appointment-details .details-content .general-content .right-content .col-item span.name-content {
            display: inline-block;
            width: 100%;
            font-size: 16px;
            color: #0282DA;
            font-weight: 500;
            white-space: nowrap;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 10px; }
            #appointment-details .details-content .general-content .left-content .col-item span.name-content button, #appointment-details .details-content .general-content .right-content .col-item span.name-content button {
              width: 100%;
              background: #0282DA;
              border: 0;
              padding: 3px 5px;
              border-radius: 20px;
              color: #fff;
              font-size: 13px; }
          #appointment-details .details-content .general-content .left-content .col-item .image-content img, #appointment-details .details-content .general-content .right-content .col-item .image-content img {
            width: 25px; }
            #appointment-details .details-content .general-content .left-content .col-item .image-content img:not(.service-logo), #appointment-details .details-content .general-content .right-content .col-item .image-content img:not(.service-logo) {
              border-radius: 10px; }
      #appointment-details .details-content .general-content .right-content {
        padding-left: 0; }

.main-content {
  width: 100% !important;
  margin-top: 0 !important; }
  .main-content .appointments-top-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 26px;
    padding: 11px 20px 10px 20px; }
    @media all and (max-width: 576px) {
      .main-content .appointments-top-bar {
        flex-direction: column; } }
    .main-content .appointments-top-bar .title-appointments {
      align-self: center; }
      .main-content .appointments-top-bar .title-appointments p {
        font: 400 22px/25px "Roboto", sans-serif;
        color: #000000; }
    .main-content .appointments-top-bar .top-bar-right {
      display: flex;
      justify-content: flex-end;
      width: 30%; }
      @media all and (max-width: 1024px) {
        .main-content .appointments-top-bar .top-bar-right {
          flex-direction: column; } }
      @media all and (max-width: 576px) {
        .main-content .appointments-top-bar .top-bar-right {
          margin: 0 auto;
          width: 100%; } }

.round-shape {
  border-radius: 50% !important; }

.main-content.appointments,
.main-content.reminders {
  display: grid; }

.MuiPaper-rounded .MuiTableRow-root .service-name {
  width: 45%;
  min-width: 85px; }

.MuiPaper-rounded .MuiTableRow-root .duration, .MuiPaper-rounded .MuiTableRow-root .server-name {
  font: 400 14px/150% "Roboto", sans-serif;
  color: #A4A4A4;
  margin-bottom: 0;
  margin-top: -6px; }

.MuiPaper-rounded .MuiTableRow-root .icon-edit {
  margin-right: 20px; }

.MuiPaper-rounded .MuiTableRow-root .status {
  max-width: 94px; }
  .MuiPaper-rounded .MuiTableRow-root .status p {
    font: 400 16px/25px "Roboto", sans-serif;
    text-align: center;
    color: #FFFFFF;
    padding: 1px 0;
    margin-bottom: 0; }

.MuiPaper-rounded .MuiTableRow-root .appointment-status {
  text-align: center;
  padding: 4px 0;
  color: #FFFFFF;
  border-radius: 3px; }
  .MuiPaper-rounded .MuiTableRow-root .appointment-status.pending {
    background-color: #F3B175; }
  .MuiPaper-rounded .MuiTableRow-root .appointment-status.finished {
    background-color: #0282DA;
    cursor: default; }
  .MuiPaper-rounded .MuiTableRow-root .appointment-status.cancelled {
    background-color: #A4A4A4;
    cursor: default; }
  .MuiPaper-rounded .MuiTableRow-root .appointment-status.accepted {
    background-color: #9DCC9B; }
  .MuiPaper-rounded .MuiTableRow-root .appointment-status.request {
    background-color: #E4B400 !important; }
  .MuiPaper-rounded .MuiTableRow-root .appointment-status.noShow {
    background-color: #F12B2C !important; }

.MuiPaper-rounded .MuiTableRow-root .appointment-duration,
.MuiPaper-rounded .MuiTableRow-root .staff-name {
  font-size: 14px;
  color: #A4A4A4; }

.appointment-status {
  text-align: center;
  padding: 4px 0;
  color: #FFFFFF;
  border-radius: 3px;
  height: 30px; }
  .appointment-status .MuiOutlinedInput-inputMarginDense {
    padding-top: 4px;
    padding-bottom: 4px; }
  .appointment-status .MuiMenuItem-root {
    color: unset; }
  .appointment-status .MuiListItem-button:hover {
    background-color: transparent; }
  .appointment-status.pending {
    background-color: #F3B175; }
  .appointment-status.finished {
    background-color: #0282DA; }
  .appointment-status.cancelled {
    background-color: #A4A4A4; }
  .appointment-status.accepted {
    background-color: #9DCC9B !important; }
  .appointment-status.request {
    background-color: #E4B400 !important; }
  .appointment-status.noShow {
    background-color: #F12B2C !important; }

.appointment-date,
.appointment-company-name {
  font-weight: 600 !important;
  font-size: 16px; }

.filter-menu, .filter-staff-menu {
  margin-top: 19px; }
  .filter-menu ul, .filter-staff-menu ul {
    padding: 25px;
    max-width: 280px; }
    .filter-menu ul h5, .filter-staff-menu ul h5 {
      font-size: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-weight: 600; }
    .filter-menu ul li, .filter-staff-menu ul li {
      font: 500 14px/150% "Roboto", sans-serif;
      color: #A4A4A4;
      padding: 0 !important;
      display: flex;
      justify-content: space-between; }
      .filter-menu ul li .filter-label, .filter-staff-menu ul li .filter-label {
        align-self: center; }
      .filter-menu ul li .MuiFormControl-marginNormal, .filter-staff-menu ul li .MuiFormControl-marginNormal {
        width: 150px !important;
        border: 1px solid #A4A4A4;
        border-radius: 4px;
        padding-left: 6px;
        margin-top: 8px; }
    .filter-menu ul .MuiInput-underline:after, .filter-staff-menu ul .MuiInput-underline:after {
      border-bottom: none !important; }
    .filter-menu ul .MuiInput-underline:before, .filter-staff-menu ul .MuiInput-underline:before {
      border-bottom: none !important; }
    .filter-menu ul .date-picker, .filter-staff-menu ul .date-picker {
      height: 44px !important; }
      .filter-menu ul .date-picker:hover, .filter-staff-menu ul .date-picker:hover {
        background-color: transparent !important; }
      .filter-menu ul .date-picker .MuiIconButton-root, .filter-staff-menu ul .date-picker .MuiIconButton-root {
        padding: 4px; }

.filter-staff-menu .MuiMenu-paper {
  top: 100px !important; }

.filter-staff-menu ul {
  top: 0;
  padding: 15px;
  max-width: 300px; }
  .filter-staff-menu ul h5 {
    font-size: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 500; }

.date-to {
  margin-left: 5px; }

.date-from .MuiInputBase-input, .date-to .MuiInputBase-input {
  padding: 4px 0 7px !important; }

.date-from p, .date-to p {
  font: 500 14px/150% "Roboto", sans-serif;
  color: #A4A4A4;
  margin-top: 16px;
  margin-bottom: 0 !important; }

.main-content.appointments .box-general {
  margin-top: 50px; }

.appointments-page {
  height: calc(100vh - 129px);
  display: flex;
  justify-content: center;
  align-items: center; }
  @media all and (max-width: 550px) {
    .appointments-page img {
      max-width: 320px;
      width: 100%; } }

.customers-page,
.industry-page,
.company-page,
.service-page,
.staff-page {
  height: calc(100vh - 169px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: absolute;
  right: 0;
  left: 0;
  z-index: -1; }
  @media all and (max-width: 768px) {
    .customers-page,
    .industry-page,
    .company-page,
    .service-page,
    .staff-page {
      position: unset; } }
  @media all and (max-width: 550px) {
    .customers-page img,
    .industry-page img,
    .company-page img,
    .service-page img,
    .staff-page img {
      max-width: 320px;
      width: 100%; } }

.not-work-day {
  height: calc(100vh - 128px - 164px);
  display: flex;
  justify-content: center;
  align-items: center; }

.appointment-title {
  border-right: 2px solid #A4A4A4;
  padding-right: 24px;
  margin-right: 24px; }

.selectSize.several-company {
  margin-left: 10px; }

.calendar-none .fc-unthemed th {
  border: none; }

.calendar-none .fc-body {
  display: none; }

.MuiMenuItem-root.menu-item-status {
  width: 146px;
  height: 30px;
  display: flex;
  justify-content: center;
  padding: 4px 0;
  color: #FFFFFF;
  border-radius: 3px; }

.default-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-right: 12px;
  border-radius: 50px;
  background: #F2F9FD;
  border: 0.5px solid #A2D1F2;
  color: #A2D1F2; }

.avatar-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-right: 12px;
  border-radius: 50px; }

.statusItem {
  background-color: unset !important; }
  .statusItem:hover {
    background-color: rgba(0, 0, 0, 0.08) !important; }

.appointment-drawer .MuiDrawer-paperAnchorRight {
  flex-direction: column;
  justify-content: space-between; }

.appointment-drawer .drawer-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px 24px 0 24px; }
  .appointment-drawer .drawer-content .title-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px; }
    .appointment-drawer .drawer-content .title-content .title {
      font-size: 18px;
      font-weight: 700;
      margin: 0; }
    .appointment-drawer .drawer-content .title-content .close-icon {
      cursor: pointer;
      color: #A4A4A4; }
  .appointment-drawer .drawer-content .details-content .status-content {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .appointment-drawer .drawer-content .details-content .status-content .status {
      font-weight: 700;
      font-size: 14px; }
    .appointment-drawer .drawer-content .details-content .status-content .status-color-upcoming {
      color: #9DCC9B; }
    .appointment-drawer .drawer-content .details-content .status-content .status-color-cancel {
      color: #A4A4A4; }
    .appointment-drawer .drawer-content .details-content .status-content .status-color-finished {
      color: #0282DA; }
    .appointment-drawer .drawer-content .details-content .status-content .status-color-pending {
      color: #F3B175; }
    .appointment-drawer .drawer-content .details-content .status-content .status-color-request {
      color: #E4B400; }
    .appointment-drawer .drawer-content .details-content .status-content .status-color-noShow {
      color: #F12B2C; }
  .appointment-drawer .drawer-content .details-content .detail-text {
    font-weight: 500;
    font-size: 14px;
    color: #393939; }
  .appointment-drawer .drawer-content .details-content .source {
    margin-bottom: 30px; }
  .appointment-drawer .drawer-content .details-content .content-item {
    padding: 15px 0;
    border: 1px solid #E9E9E9; }
    .appointment-drawer .drawer-content .details-content .content-item .info-text {
      color: #393939;
      font-size: 14px;
      font-weight: 500; }
  .appointment-drawer .drawer-content .input-content {
    margin-bottom: 20px; }
    .appointment-drawer .drawer-content .input-content .avatarAppointments {
      width: 20px;
      height: 20px;
      margin-right: 10px; }
    .appointment-drawer .drawer-content .input-content.details {
      margin-bottom: 0; }
      .appointment-drawer .drawer-content .input-content.details .final-price {
        margin-top: 20px;
        background: #FAFAFA;
        border-radius: 4px;
        padding: 10px 26px; }
        .appointment-drawer .drawer-content .input-content.details .final-price .text {
          color: #393939;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 15px; }
    .appointment-drawer .drawer-content .input-content:not(:last-child) {
      border-bottom: 1px solid #E9E9E9;
      padding-bottom: 15px; }
    .appointment-drawer .drawer-content .input-content .input-title-box {
      display: flex;
      align-items: center;
      margin-bottom: 15px; }
      .appointment-drawer .drawer-content .input-content .input-title-box .input-title {
        font: normal 600 14px/19px Nunito Sans, sans-serif;
        color: #0282DA;
        margin-left: 11px; }
      .appointment-drawer .drawer-content .input-content .input-title-box.start {
        align-items: start;
        padding-top: 15px;
        margin-bottom: 0; }
        .appointment-drawer .drawer-content .input-content .input-title-box.start .info-text {
          text-transform: capitalize;
          color: #393939;
          font-size: 14px;
          font-weight: 500;
          margin-left: 11px;
          margin-top: 3px; }
    .appointment-drawer .drawer-content .input-content .input {
      width: 100%;
      border-color: #A4A4A4;
      border-radius: 4px;
      font-size: 16px; }
      .appointment-drawer .drawer-content .input-content .input input {
        font-size: 15px; }
    .appointment-drawer .drawer-content .input-content .label {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 16px;
      color: #393939; }
    .appointment-drawer .drawer-content .input-content .service-info {
      display: flex;
      align-items: center;
      justify-content: end;
      color: #393939;
      margin-top: 5px; }
      .appointment-drawer .drawer-content .input-content .service-info.detail {
        justify-content: start; }
        .appointment-drawer .drawer-content .input-content .service-info.detail .text {
          font-weight: 500; }
      .appointment-drawer .drawer-content .input-content .service-info .text {
        font-weight: 400;
        font-size: 14px; }
      .appointment-drawer .drawer-content .input-content .service-info svg {
        font-size: 15px;
        margin-left: 5px; }

.appointment-drawer .buttons-box {
  margin-bottom: 20px;
  display: flex;
  justify-content: end;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  width: 100%; }
  .appointment-drawer .buttons-box.content-between {
    justify-content: space-between; }
  .appointment-drawer .buttons-box .btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 36px; }
  .appointment-drawer .buttons-box .create-btn {
    height: 36px; }
  .appointment-drawer .buttons-box .right-button-content {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    grid-gap: 8px;
    gap: 8px; }

.delete-btn-text {
  color: #F12B2C;
  font: normal 600 14px/19px Nunito Sans, sans-serif;
  margin-left: 8px; }

.MuiAutocomplete-option:has(.walk-in-option) {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  background-color: #FAFAFA !important;
  z-index: 999;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.walk-in-option {
  display: block !important;
  padding: 0 !important; }
  .walk-in-option.MuiListItem-button:hover {
    background-color: unset !important;
    width: 100%; }
  .walk-in-option .walk-in-desc {
    color: #A4A4A4;
    font: normal 400 14px/19px Nunito sans, sans serif;
    margin: 3px 0 0; }

.MuiAutocomplete-listbox:has(.walk-in-option) {
  padding-top: 0 !important;
  border-radius: 4px; }

#styles_appointmentContainer__1m2P8 .styles_categoriesContainer__Qsuny {
  height: 100%;
  border-right: 1px solid #E3E5E6; }
  #styles_appointmentContainer__1m2P8 .styles_categoriesContainer__Qsuny .styles_titleBox__DMblY {
    padding: 22px; }
    #styles_appointmentContainer__1m2P8 .styles_categoriesContainer__Qsuny .styles_titleBox__DMblY p {
      font-family: 'Nunito', sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #393939;
      margin-bottom: 0;
      margin-top: 0; }
  #styles_appointmentContainer__1m2P8 .styles_categoriesContainer__Qsuny .styles_categories__1rSkJ {
    padding: 0 8px;
    max-height: calc(100% - 66px);
    overflow-y: auto; }

#styles_appointmentContainer__1m2P8 .styles_tableBox__1y0o6 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 129px);
  border-top: 1px solid #E9E9E9; }
  #styles_appointmentContainer__1m2P8 .styles_tableBox__1y0o6 .styles_table-row__1CmGz {
    color: red; }

#styles_headerContainer__2W2Rd .styles_header__19wyN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 24px; }
  #styles_headerContainer__2W2Rd .styles_header__19wyN .styles_searchBox__KdNrr {
    max-width: 250px;
    width: 100%; }

.styles_fromText__3lLWf {
  color: #A4A4A4;
  font: normal 500 14px/19px Nunito Sans, sans-serif; }

.styles_buttonBox__2uWF4 {
  height: 39px; }
  .styles_buttonBox__2uWF4 button {
    height: 100%;
    padding: 4px 16px;
    font: normal 500 14px/19px Nunito Sans, sans-serif; }

.styles_serviceModal__jxFSz .styles_content__2Hdh7 .styles_imgBox__1wfxS {
  display: flex;
  justify-content: center;
  margin-bottom: 24px; }

.styles_serviceModal__jxFSz .styles_content__2Hdh7 .styles_contentText__3hbxS {
  font: normal 400 14px/19px Nunito Sans, sans-serif;
  color: #393939;
  text-align: center;
  margin-bottom: 0; }

.appointment-filter-drawer .MuiListItem-gutters {
  padding-left: 0;
  padding-right: 0; }

.appointment-filter-drawer .MuiFormControl-marginNormal {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.appointment-filter-drawer .MuiMenuItem-root {
  padding-top: 0;
  padding-bottom: 0; }

.appointment-filter-drawer .save-btn {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 20px; }
  .appointment-filter-drawer .save-btn button {
    width: 100%; }

.appointment-filter-drawer .title {
  color: #393939;
  font: normal 700 14px/19px Nunito Sans, sans-serif; }

.appointment-filter-drawer .status-name {
  color: #393939;
  font: normal 500 14px/19px Nunito Sans, sans-serif;
  margin: 3px 0 !important; }

.appointment-filter-drawer .status-checkbox {
  padding: 0;
  width: 18px;
  height: 18px; }

.appointment-filter-drawer .MuiDrawer-paperAnchorRight {
  flex-direction: column;
  justify-content: space-between; }

.appointment-filter-drawer .drawer-content {
  padding-left: 24px;
  padding-right: 24px;
  width: 320px; }
  .appointment-filter-drawer .drawer-content .title-content {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .appointment-filter-drawer .drawer-content .title-content .title {
      font-size: 18px;
      font-weight: 700; }
    .appointment-filter-drawer .drawer-content .title-content .close-icon {
      cursor: pointer;
      color: #A4A4A4; }
  .appointment-filter-drawer .drawer-content .input-content {
    margin-bottom: 20px; }
    .appointment-filter-drawer .drawer-content .input-content .input {
      width: 100%;
      border-color: #A4A4A4;
      border-radius: 4px;
      font-size: 16px; }
      .appointment-filter-drawer .drawer-content .input-content .input input {
        font-size: 15px; }
      .appointment-filter-drawer .drawer-content .input-content .input .MuiInputBase-root {
        height: 48px; }
    .appointment-filter-drawer .drawer-content .input-content .label {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 16px;
      color: #393939; }

.appointment-filter-drawer .buttons-box {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  width: 100%; }
  .appointment-filter-drawer .buttons-box .btn {
    width: 100%; }

#header {
  background: #e4ebf1; }
  #header .roles {
    display: flex; }
    #header .roles .role {
      padding: 10px 30px;
      color: #000000;
      background: #FFFFFF;
      cursor: pointer;
      font-weight: bold; }
      #header .roles .role.selected {
        background: #1a85ff;
        color: white; }

.unsubscribe {
  width: 50%;
  margin: 40px auto; }
  .unsubscribe .logo {
    text-align: center; }
  .unsubscribe .content {
    text-align: center;
    margin-top: 30px; }
    .unsubscribe .content p {
      color: #393939;
      margin-top: 5px;
      margin-bottom: 5px; }
    .unsubscribe .content .line {
      color: #393939;
      border-width: 0.5px;
      margin-bottom: 20px;
      margin-top: 20px; }
    .unsubscribe .content .buttons-group .yes {
      margin-right: 10px; }
  .unsubscribe .unsubscribe-success {
    text-align: center; }
    .unsubscribe .unsubscribe-success p {
      color: #393939;
      font-size: 16px;
      font-weight: 400;
      margin-top: 20px; }

.unsubscribe-loader {
  text-align: center;
  margin-top: 40px; }

#customers .page-top-bar .filter-select {
  width: 150px;
  margin: 0 20px; }
  #customers .page-top-bar .filter-select .css-yk16xz-control:active {
    height: 40px; }

#customers .customers-line .customer-item .actions-dropdown {
  position: absolute;
  top: 5px;
  right: 15px; }
  #customers .customers-line .customer-item .actions-dropdown .dropdown-toggle {
    flex-direction: row;
    align-items: center; }
    #customers .customers-line .customer-item .actions-dropdown .dropdown-toggle:before, #customers .customers-line .customer-item .actions-dropdown .dropdown-toggle i {
      margin-right: 2px; }

#customers .customers-line .customer-item .image-content {
  width: 100px;
  height: 100px;
  border-radius: 50%; }
  #customers .customers-line .customer-item .image-content img {
    min-width: 100%;
    width: 100%;
    height: 100%; }

#customers .customers-line .customer-item:last-child {
  margin-right: 0; }

#customers .customer-item-box {
  margin-top: 50px; }

.add-staff-btn {
  margin-top: 8px !important;
  max-height: 55px; }

.country-flag {
  margin-right: 5px;
  vertical-align: middle; }

.appointments-delete-context {
  font: normal normal normal 16px/21px "Roboto", sans-serif;
  letter-spacing: 0.15px;
  color: #000305; }

.appointments-delete-context p:not(:first-child) {
  line-height: 5px; }

.appointments-delete-context p:nth-child(1) {
  padding-bottom: 20px; }

.appointments-delete-context-text {
  color: #0282DA; }

.justify-content-right .save-btn {
  border-radius: 5px !important;
  color: #FFFFFF !important;
  text-transform: none !important; }
  .justify-content-right .save-btn:hover {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
    text-shadow: 0 0 6px white;
    transition: all 0.4s ease 0s; }

.dialog-delete-modal .save-btn {
  border-radius: 5px !important;
  color: #FFFFFF !important;
  text-transform: none !important; }

.tab-customers .box-general {
  box-shadow: none; }
  .tab-customers .box-general .no-customer {
    margin: auto;
    font-size: 2em;
    color: #8c949d; }

.customer-company-name {
  display: flex; }

.customers-customers-line .col-auto.customer-item .actions-dropdown-customer {
  padding: 0 !important;
  position: relative; }
  .customers-customers-line .col-auto.customer-item .actions-dropdown-customer .image-content {
    margin-top: 26.79px;
    text-align: center; }
    .customers-customers-line .col-auto.customer-item .actions-dropdown-customer .image-content img {
      width: 47%; }
  .customers-customers-line .col-auto.customer-item .actions-dropdown-customer .customer-email,
  .customers-customers-line .col-auto.customer-item .actions-dropdown-customer .customer-company-name,
  .customers-customers-line .col-auto.customer-item .actions-dropdown-customer .company-title {
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 10px;
    margin: 0;
    white-space: nowrap;
    text-align: center;
    font-size: 11px;
    line-height: 10px; }
  .customers-customers-line .col-auto.customer-item .actions-dropdown-customer .customer-company-name {
    padding: 0 1px; }
  .customers-customers-line .col-auto.customer-item .actions-dropdown-customer .company-title {
    padding-left: 6px;
    font-size: 12px; }

.second-modal table {
  margin-bottom: 0 !important; }

.second-modal .alert-title {
  font-weight: normal; }

.second-modal .table-title {
  font-size: 18px; }

.second-modal .close-btn {
  top: -15px;
  padding: 0; }

.second-modal .permission-name {
  font-size: 22px; }

.row {
  cursor: pointer; }
  .row .default-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    line-height: 32px;
    margin-right: 12px;
    border-radius: 50px; }
  .row .avatar-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    line-height: 32px;
    margin-right: 12px;
    border-radius: 50px; }
  .row .row-item {
    font-size: 14px;
    color: #393939;
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; }
    .row .row-item.action {
      padding: 6px; }
    .row .row-item.email {
      color: #0282DA; }
    .row .row-item .action-button {
      padding: 10px; }
    .row .row-item.selectCheckbox {
      padding: 0 16px 0 16px; }

.data-table {
  min-width: 750px; }
  .data-table .MuiCheckbox-root {
    color: #E3E5E6; }
    .data-table .MuiCheckbox-root:hover {
      background-color: unset !important;
      color: #0282DA; }
  .data-table .checkbox {
    padding: 0 16px 0 16px; }

.menu-list {
  -webkit-filter: drop-shadow(0px 32px 64px rgba(146, 148, 165, 0.16));
          filter: drop-shadow(0px 32px 64px rgba(146, 148, 165, 0.16)); }
  .menu-list .item {
    padding-bottom: 10px;
    padding-top: 10px; }
    .menu-list .item .icon {
      color: #393939;
      margin-right: 15px; }
      .menu-list .item .icon.delete-icon {
        color: #F12B2C; }
    .menu-list .item p {
      color: #393939; }
      .menu-list .item p.delete {
        color: #F12B2C; }

.pagination .MuiTypography-root {
  color: #9E9E9E; }

.delete-modal .MuiDialog-paper {
  width: 360px;
  min-width: 360px; }
  .delete-modal .MuiDialog-paper .modal-content {
    padding-top: 0; }
    .delete-modal .MuiDialog-paper .modal-content .close {
      text-align: end;
      margin-top: 20px; }
      .delete-modal .MuiDialog-paper .modal-content .close button {
        padding: 0; }
    .delete-modal .MuiDialog-paper .modal-content .warning-icon {
      width: 50px;
      height: 50px;
      background-color: #FFF3F3;
      border-radius: 50px;
      text-align: center;
      line-height: 50px;
      margin: 0 auto 20px; }
    .delete-modal .MuiDialog-paper .modal-content .title {
      text-align: center;
      font-size: 16px;
      color: #393939; }
  .delete-modal .MuiDialog-paper .buttons {
    justify-content: center;
    padding: 20px 24px; }

.button-box {
  display: inline-table;
  height: 39px; }
  .button-box button {
    height: 100%;
    padding: 4px 16px;
    font: normal 500 14px/19px Nunito Sans, sans-serif; }

.drawer .MuiDrawer-paperAnchorRight {
  flex-direction: column;
  justify-content: space-between; }

.drawer .drawer-content {
  padding-left: 24px;
  padding-right: 24px;
  width: 320px; }
  .drawer .drawer-content .title-content {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .drawer .drawer-content .title-content .title {
      font-size: 18px;
      font-weight: 700; }
    .drawer .drawer-content .title-content .close-icon {
      cursor: pointer;
      color: #A4A4A4; }
  .drawer .drawer-content .input-content {
    margin-bottom: 20px; }
    .drawer .drawer-content .input-content .email-bottom-text {
      margin-top: 8px;
      margin-bottom: 0;
      color: #A4A4A4;
      font: normal 400 12px/16px Nunito Sans, sans-serif; }
    .drawer .drawer-content .input-content .MuiInputLabel-outlined.MuiInputLabel-marginDense {
      -webkit-transform: translate(14px, 18px) scale(1) !important;
              transform: translate(14px, 18px) scale(1) !important; }
    .drawer .drawer-content .input-content .MuiInputLabel-outlined.MuiInputLabel-shrink {
      -webkit-transform: translate(14px, -6px) scale(0.75) !important;
              transform: translate(14px, -6px) scale(0.75) !important; }
    .drawer .drawer-content .input-content .input {
      width: 100%;
      border-color: #A4A4A4;
      border-radius: 4px;
      font-size: 16px; }
      .drawer .drawer-content .input-content .input input {
        font-size: 15px; }
      .drawer .drawer-content .input-content .input .MuiInputBase-root {
        height: 48px; }
    .drawer .drawer-content .input-content .label {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 16px;
      color: #393939; }

.drawer .save-btn {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 20px; }
  .drawer .save-btn button {
    width: 100%; }

.drawer2 {
  padding-left: 24px;
  padding-right: 24px; }
  .drawer2 .title-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px; }
    .drawer2 .title-content .title {
      font-size: 18px;
      font-weight: 700;
      margin: 0; }
    .drawer2 .title-content .close-icon {
      cursor: pointer;
      color: #A4A4A4; }
  .drawer2 .content {
    width: 658px;
    height: 100%;
    display: flex; }
    .drawer2 .content .left-content {
      max-width: 260px;
      width: 100%;
      height: 100%;
      border-right: 1px solid #E3E5E6;
      padding-bottom: 24px; }
      .drawer2 .content .left-content .left-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%; }
        .drawer2 .content .left-content .left-container .delete-btn-section {
          padding: 0 24px; }
        .drawer2 .content .left-content .left-container .edit-btn {
          text-align: right;
          padding-right: 24px;
          padding-left: 24px; }
          .drawer2 .content .left-content .left-container .edit-btn button span {
            color: #393939;
            font: normal 600 14px/19px Nunito Sans, sans-serif;
            margin-left: 8px; }
        .drawer2 .content .left-content .left-container .detail-section {
          padding-right: 24px;
          padding-left: 24px; }
          .drawer2 .content .left-content .left-container .detail-section .avatar-section .avatar {
            display: flex;
            justify-content: center;
            margin-top: 24px;
            margin-bottom: 12px; }
            .drawer2 .content .left-content .left-container .detail-section .avatar-section .avatar .no-avatar-circle {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100px;
              height: 100px;
              margin-right: 12px;
              border-radius: 50px;
              background: #F2F9FD;
              color: #A2D1F2;
              border: none;
              font: normal 700 48px/65px Nunito Sans, sans-serif; }
          .drawer2 .content .left-content .left-container .detail-section .avatar-section .customer-name {
            color: #393939;
            font: normal 600 16px/22px Nunito Sans, sans-serif;
            text-align: center;
            margin-bottom: 24px;
            margin-top: 0; }
          .drawer2 .content .left-content .left-container .detail-section .mail-detail {
            display: flex;
            margin-bottom: 14px; }
            .drawer2 .content .left-content .left-container .detail-section .mail-detail .mail-text {
              color: #0282DA;
              font: normal 400 14px/19px Nunito Sans, sans-serif;
              margin-left: 8px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: noWrap; }
          .drawer2 .content .left-content .left-container .detail-section .phone-detail {
            display: flex; }
            .drawer2 .content .left-content .left-container .detail-section .phone-detail .phone-text {
              color: #393939;
              font: normal 400 14px/19px Nunito Sans, sans-serif;
              margin-left: 8px; }
        .drawer2 .content .left-content .left-container .divider-border {
          border-bottom: 1px solid #E3E5E6;
          margin: 40px 24px 16px 24px; }
        .drawer2 .content .left-content .left-container .detail-items {
          padding-right: 8px;
          padding-left: 8px; }
        .drawer2 .content .left-content .left-container .new-appointment {
          padding-right: 24px;
          padding-left: 24px; }
          .drawer2 .content .left-content .left-container .new-appointment .new-appointment-text {
            color: #0282DA;
            font: normal 600 14px/19px Nunito Sans, sans-serif;
            margin-left: 8px; }
        .drawer2 .content .left-content .left-container .delete-btn-text {
          color: #F12B2C;
          font: normal 600 14px/19px Nunito Sans, sans-serif;
          margin-left: 8px; }
    .drawer2 .content .rightContent {
      max-width: 398px;
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between; }
      .drawer2 .content .rightContent .editCustomerText {
        color: #393939;
        font: normal 700 14px/19px Nunito Sans, sans-serif;
        margin: 0 24px 24px; }
      .drawer2 .content .rightContent .MuiDrawer-paperAnchorRight {
        flex-direction: column;
        justify-content: space-between; }
      .drawer2 .content .rightContent .drawer-content {
        padding-left: 24px;
        padding-right: 24px; }
        .drawer2 .content .rightContent .drawer-content .editCustomerTitle {
          color: #393939;
          font: normal 700 16px/19px Nunito Sans, sans-serif;
          margin: 0 0 20px 0; }
        .drawer2 .content .rightContent .drawer-content .input-content {
          margin-bottom: 20px; }
          .drawer2 .content .rightContent .drawer-content .input-content .email-bottom-text {
            margin-top: 8px;
            margin-bottom: 0;
            color: #A4A4A4;
            font: normal 400 12px/16px Nunito Sans, sans-serif; }
          .drawer2 .content .rightContent .drawer-content .input-content .MuiInputLabel-outlined.MuiInputLabel-marginDense {
            -webkit-transform: translate(14px, 18px) scale(1) !important;
                    transform: translate(14px, 18px) scale(1) !important; }
          .drawer2 .content .rightContent .drawer-content .input-content .MuiInputLabel-outlined.MuiInputLabel-shrink {
            -webkit-transform: translate(14px, -6px) scale(0.75) !important;
                    transform: translate(14px, -6px) scale(0.75) !important; }
          .drawer2 .content .rightContent .drawer-content .input-content .input {
            width: 100%;
            border-color: #A4A4A4;
            border-radius: 4px;
            font-size: 16px; }
            .drawer2 .content .rightContent .drawer-content .input-content .input input {
              font-size: 15px; }
            .drawer2 .content .rightContent .drawer-content .input-content .input .MuiInputBase-root {
              height: 48px; }
          .drawer2 .content .rightContent .drawer-content .input-content .label {
            margin-bottom: 8px;
            font-weight: 400;
            font-size: 16px;
            color: #393939; }
      .drawer2 .content .rightContent .save-btn {
        display: flex;
        justify-content: end;
        align-items: center;
        grid-gap: 8px;
        gap: 8px;
        padding: 20px; }
        .drawer2 .content .rightContent .save-btn button {
          width: 100%; }

.appointments-section-customers {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-gap: 5px;
  gap: 5px; }
  .appointments-section-customers .appointments-pagination-customer {
    max-height: calc(100vh - 232px);
    height: 100%;
    overflow-y: auto; }
    .appointments-section-customers .appointments-pagination-customer .app-detail-box {
      padding-left: 24px;
      padding-right: 24px;
      margin-bottom: 20px; }
  .appointments-section-customers .appointment-date {
    color: #393939;
    font: normal 600 14px/22px Nunito Sans, sans-serif;
    margin-top: 0; }
  .appointments-section-customers .appointment-box {
    width: 100%;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 8px; }
    .appointments-section-customers .appointment-box .statusBg {
      border-radius: 4px 0 0 4px;
      position: absolute;
      width: 8px;
      height: 100%;
      left: 0; }
    .appointments-section-customers .appointment-box .box-content {
      width: 100%; }
      .appointments-section-customers .appointment-box .box-content .time-box {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 4px 4px 0 0;
        padding: 3px 10px;
        color: #FFFFFF; }
        .appointments-section-customers .appointment-box .box-content .time-box span {
          margin-top: 0;
          margin-bottom: 0;
          color: #FFFFFF;
          font: normal 500 14px/19px Nunito Sans, sans-serif; }
      .appointments-section-customers .appointment-box .box-content .status-name-customer span {
        color: #FFFFFF;
        font: normal 500 14px/19px Nunito Sans, sans-serif;
        text-transform: capitalize; }
      .appointments-section-customers .appointment-box .box-content .detail-box {
        width: 100%;
        padding: 10px; }
        .appointments-section-customers .appointment-box .box-content .detail-box p {
          margin-top: 0;
          margin-bottom: 0; }
        .appointments-section-customers .appointment-box .box-content .detail-box .service-name {
          color: #393939;
          font: normal 600 16px/22px Nunito Sans, sans-serif;
          margin-bottom: 2px; }
        .appointments-section-customers .appointment-box .box-content .detail-box .customer-name {
          color: #323232;
          font: normal 400 12px/16px Nunito Sans, sans-serif; }

.drawer .MuiDrawer-paperAnchorRight {
  flex-direction: column;
  justify-content: space-between; }

.drawer .drawer-content {
  padding-left: 24px;
  padding-right: 24px;
  width: 320px; }
  .drawer .drawer-content .title-content {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .drawer .drawer-content .title-content .title {
      font-size: 18px;
      font-weight: 700; }
    .drawer .drawer-content .title-content .close-icon {
      cursor: pointer;
      color: #A4A4A4; }
  .drawer .drawer-content .input-content {
    margin-bottom: 20px; }
    .drawer .drawer-content .input-content .MuiInputLabel-outlined.MuiInputLabel-marginDense {
      -webkit-transform: translate(14px, 18px) scale(1) !important;
              transform: translate(14px, 18px) scale(1) !important; }
    .drawer .drawer-content .input-content .MuiInputLabel-outlined.MuiInputLabel-shrink {
      -webkit-transform: translate(14px, -6px) scale(0.75) !important;
              transform: translate(14px, -6px) scale(0.75) !important; }
    .drawer .drawer-content .input-content .input {
      width: 100%;
      border-color: #A4A4A4;
      border-radius: 4px;
      font-size: 16px; }
      .drawer .drawer-content .input-content .input input {
        font-size: 15px; }
      .drawer .drawer-content .input-content .input .MuiInputBase-root {
        height: 48px; }
    .drawer .drawer-content .input-content .label {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 16px;
      color: #393939; }
    .drawer .drawer-content .input-content.profession .MuiInputBase-root {
      height: 48px; }
  .drawer .drawer-content .dropzone {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 auto 30px;
    cursor: pointer; }
    .drawer .drawer-content .dropzone .image {
      width: 100px;
      height: 100px;
      border-radius: 50px; }
    .drawer .drawer-content .dropzone .action {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 32px;
      height: 32px;
      border-radius: 50px;
      background: #A2D1F2;
      border: 2px solid #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center; }
      .drawer .drawer-content .dropzone .action svg {
        color: white; }

.drawer .save-btn {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 20px; }
  .drawer .save-btn button {
    width: 100%; }

.drawer2 {
  padding-left: 24px;
  padding-right: 24px; }
  .drawer2 .title-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px; }
    .drawer2 .title-content .title {
      font-size: 18px;
      font-weight: 700;
      margin: 0; }
    .drawer2 .title-content .close-icon {
      cursor: pointer;
      color: #A4A4A4; }
  .drawer2 .content {
    width: 658px;
    height: 100%;
    display: flex; }
    .drawer2 .content .left-content {
      max-width: 260px;
      width: 100%;
      height: 100%;
      border-right: 1px solid #E3E5E6;
      padding-bottom: 24px; }
      .drawer2 .content .left-content .left-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%; }
        .drawer2 .content .left-content .left-container .delete-btn-section {
          padding: 0 24px; }
        .drawer2 .content .left-content .left-container .edit-btn {
          text-align: right;
          padding-right: 24px;
          padding-left: 24px; }
          .drawer2 .content .left-content .left-container .edit-btn button span {
            color: #393939;
            font: normal 600 14px/19px Nunito Sans, sans-serif;
            margin-left: 8px; }
        .drawer2 .content .left-content .left-container .detail-section {
          padding-right: 24px;
          padding-left: 24px; }
          .drawer2 .content .left-content .left-container .detail-section .avatar-section .avatar {
            display: flex;
            justify-content: center;
            margin-top: 24px;
            margin-bottom: 12px; }
          .drawer2 .content .left-content .left-container .detail-section .avatar-section .customer-name {
            color: #393939;
            font: normal 600 16px/22px Nunito Sans, sans-serif;
            text-align: center;
            margin-bottom: 24px;
            margin-top: 0; }
          .drawer2 .content .left-content .left-container .detail-section .mail-detail {
            display: flex;
            margin-bottom: 14px; }
            .drawer2 .content .left-content .left-container .detail-section .mail-detail .mail-text {
              color: #0282DA;
              font: normal 400 14px/19px Nunito Sans, sans-serif;
              margin-left: 8px; }
          .drawer2 .content .left-content .left-container .detail-section .phone-detail {
            display: flex; }
            .drawer2 .content .left-content .left-container .detail-section .phone-detail .phone-text {
              color: #393939;
              font: normal 400 14px/19px Nunito Sans, sans-serif;
              margin-left: 8px; }
        .drawer2 .content .left-content .left-container .divider-border {
          border-bottom: 1px solid #E3E5E6;
          margin: 40px 24px 16px 24px; }
        .drawer2 .content .left-content .left-container .detail-items {
          padding-right: 8px;
          padding-left: 8px; }
        .drawer2 .content .left-content .left-container .new-appointment {
          padding-right: 24px;
          padding-left: 24px; }
          .drawer2 .content .left-content .left-container .new-appointment .new-appointment-text {
            color: #0282DA;
            font: normal 600 14px/19px Nunito Sans, sans-serif;
            margin-left: 8px; }
        .drawer2 .content .left-content .left-container .delete-btn-text {
          color: #F12B2C;
          font: normal 600 14px/19px Nunito Sans, sans-serif;
          margin-left: 8px; }
    .drawer2 .content .rightContent {
      max-width: 398px;
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between; }
      .drawer2 .content .rightContent .editCustomerText {
        color: #393939;
        font: normal 700 16px/19px Nunito Sans, sans-serif;
        margin: 0 24px 24px; }
      .drawer2 .content .rightContent .MuiDrawer-paperAnchorRight {
        flex-direction: column;
        justify-content: space-between; }
      .drawer2 .content .rightContent .drawer-content {
        padding-left: 24px;
        padding-right: 24px; }
        .drawer2 .content .rightContent .drawer-content .dropzone {
          position: relative;
          width: 100px;
          height: 100px;
          margin: 0 auto 30px;
          cursor: pointer; }
          .drawer2 .content .rightContent .drawer-content .dropzone .image {
            width: 100px;
            height: 100px;
            border-radius: 50px; }
          .drawer2 .content .rightContent .drawer-content .dropzone .action {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 32px;
            height: 32px;
            border-radius: 50px;
            background: #A2D1F2;
            border: 2px solid #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center; }
            .drawer2 .content .rightContent .drawer-content .dropzone .action svg {
              color: white; }
        .drawer2 .content .rightContent .drawer-content .editStaffTitle {
          color: #393939;
          font: normal 700 16px/19px Nunito Sans, sans-serif;
          margin: 0 0 20px 0; }
        .drawer2 .content .rightContent .drawer-content .input-content {
          margin-bottom: 20px; }
          .drawer2 .content .rightContent .drawer-content .input-content .email-bottom-text {
            margin-top: 8px;
            margin-bottom: 0;
            color: #A4A4A4;
            font: normal 400 12px/16px Nunito Sans, sans-serif; }
          .drawer2 .content .rightContent .drawer-content .input-content .MuiInputLabel-outlined.MuiInputLabel-marginDense {
            -webkit-transform: translate(14px, 18px) scale(1) !important;
                    transform: translate(14px, 18px) scale(1) !important; }
          .drawer2 .content .rightContent .drawer-content .input-content .MuiInputLabel-outlined.MuiInputLabel-shrink {
            -webkit-transform: translate(14px, -6px) scale(0.75) !important;
                    transform: translate(14px, -6px) scale(0.75) !important; }
          .drawer2 .content .rightContent .drawer-content .input-content .input {
            width: 100%;
            border-color: #A4A4A4;
            border-radius: 4px;
            font-size: 16px; }
            .drawer2 .content .rightContent .drawer-content .input-content .input input {
              font-size: 15px; }
            .drawer2 .content .rightContent .drawer-content .input-content .input .MuiInputBase-root {
              height: 48px; }
          .drawer2 .content .rightContent .drawer-content .input-content .label {
            margin-bottom: 8px;
            font-weight: 400;
            font-size: 16px;
            color: #393939; }
      .drawer2 .content .rightContent .save-btn {
        display: flex;
        justify-content: end;
        align-items: center;
        grid-gap: 8px;
        gap: 8px;
        padding: 20px; }
        .drawer2 .content .rightContent .save-btn button {
          width: 100%; }

.appointments-section .appointments-pagination {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 173px);
  justify-content: space-between; }

.appointments-section .appointment-date {
  color: #393939;
  font: normal 600 14px/22px Nunito Sans, sans-serif; }

.appointments-section .appointment-box {
  width: 100%;
  height: 65px;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center; }
  .appointments-section .appointment-box .statusBg {
    border-radius: 4px 0 0 4px;
    position: absolute;
    width: 8px;
    height: 100%;
    left: 0; }
  .appointments-section .appointment-box .box-content {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 24px; }
    .appointments-section .appointment-box .box-content .time-box {
      border-right: 0.5px solid #A4A4A4;
      padding-right: 16px; }
      .appointments-section .appointment-box .box-content .time-box p {
        margin-top: 0;
        margin-bottom: 0;
        color: #393939;
        font: normal 600 14px/19px Nunito Sans, sans-serif; }
    .appointments-section .appointment-box .box-content .detail-box {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px; }
      .appointments-section .appointment-box .box-content .detail-box .status span {
        font: normal 600 10px/15px Nunito Sans, sans-serif;
        text-align: right;
        position: absolute;
        right: 16px;
        top: 4px; }
      .appointments-section .appointment-box .box-content .detail-box p {
        margin-top: 0;
        margin-bottom: 0; }
      .appointments-section .appointment-box .box-content .detail-box .service-name {
        color: #393939;
        font: normal 600 14px/19px Nunito Sans, sans-serif; }
      .appointments-section .appointment-box .box-content .detail-box .staff-name {
        color: #323232;
        font: normal 400 12px/16px Nunito Sans, sans-serif; }

.no-avatar-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-right: 12px;
  border-radius: 50px;
  background: #F2F9FD;
  border: none;
  color: #A2D1F2;
  font: normal 700 48px/65px Nunito Sans, sans-serif; }

.MuiTablePagination-root {
  border-top: 1px solid #E3E5E6; }

#full-calendar {
  font-family: sans-serif;
  text-align: center;
  position: relative;
  --fc-today-bg-color: #fff;
  --fc-non-business-color: rgba(211, 211, 211, 0.2); }
  #full-calendar .fc-theme-standard .fc-scrollgrid {
    border: none; }
  #full-calendar .fc-v-event {
    border: none;
    background: white !important; }
  #full-calendar .fc-timegrid-event .fc-event-main {
    padding: 0; }
  #full-calendar .fc .fc-view-harness {
    max-height: calc(100vh - 150px);
    overflow-y: auto; }
    #full-calendar .fc .fc-view-harness::-webkit-scrollbar {
      width: 0;
      height: 0; }
  #full-calendar .fc-license-message {
    display: none; }
  #full-calendar .fc-col-header .fc-timegrid-axis {
    border: 0; }
  #full-calendar .fc .fc-col-header-cell-cushion {
    padding: 16px 12px !important;
    width: 100%; }
  #full-calendar .fc-timegrid-slot-label-frame {
    line-height: 0.75rem; }
  #full-calendar .fc .fc-toolbar.fc-header-toolbar {
    padding: 24px;
    margin-bottom: 0;
    border-bottom: 1px solid #E9E9E9; }
  #full-calendar .fc-toolbar-chunk {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px; }
  #full-calendar .fc-toolbar-title {
    display: inline-block !important;
    margin-left: 10px;
    color: #393939;
    font: normal 600 14px/19px Nunito Sans, sans-serif; }
    #full-calendar .fc-toolbar-title::after {
      content: "";
      background: url(https://dev.settime.io/static/media/arrow-down.b058893e.svg) no-repeat bottom;
      width: 24px;
      height: 24px;
      font-weight: normal;
      font-style: normal;
      padding: 2px 13px;
      text-decoration: inherit; }
  #full-calendar .fc-prev-button.fc-button.fc-button-primary, #full-calendar .fc-next-button.fc-button.fc-button-primary {
    background: none;
    border: none;
    width: 37px;
    height: 37px; }
    #full-calendar .fc-prev-button.fc-button.fc-button-primary:focus, #full-calendar .fc-next-button.fc-button.fc-button-primary:focus {
      box-shadow: none; }
    #full-calendar .fc-prev-button.fc-button.fc-button-primary:hover, #full-calendar .fc-next-button.fc-button.fc-button-primary:hover {
      background-color: rgba(0, 0, 0, 0.04);
      width: 37px;
      height: 37px;
      border-radius: 50%; }
    #full-calendar .fc-prev-button.fc-button.fc-button-primary .fc-icon-chevron-left:before, #full-calendar .fc-next-button.fc-button.fc-button-primary .fc-icon-chevron-left:before {
      color: #393939; }
    #full-calendar .fc-prev-button.fc-button.fc-button-primary .fc-icon-chevron-right:before, #full-calendar .fc-next-button.fc-button.fc-button-primary .fc-icon-chevron-right:before {
      color: #393939; }
  #full-calendar .fc-prev-button.fc-button .fc-icon, #full-calendar .fc-next-button.fc-button .fc-icon {
    font-size: 16px; }
  #full-calendar .calendar-header-buttons {
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    height: 39px; }
    #full-calendar .calendar-header-buttons .filterIcon {
      height: 36px; }
    #full-calendar .calendar-header-buttons .PrivateNotchedOutline-root-1 {
      border: none; }
    #full-calendar .calendar-header-buttons .MuiSelect-icon {
      color: #393939; }
    #full-calendar .calendar-header-buttons .calendar-mode-dropdown {
      width: 115px;
      height: 36px; }
    #full-calendar .calendar-header-buttons .calendar-buttons {
      display: flex;
      align-items: center;
      grid-gap: 30px;
      gap: 30px; }
    #full-calendar .calendar-header-buttons .new-appointment-btn {
      height: 36px; }
  #full-calendar .calendar-date-picker {
    position: absolute;
    left: 108px;
    top: 34px; }
    #full-calendar .calendar-date-picker input {
      font: normal 600 14px/19px Nunito, sans-serif;
      text-transform: capitalize;
      padding: 0; }
    #full-calendar .calendar-date-picker .MuiInput-underline:before {
      border: none; }
    #full-calendar .calendar-date-picker .MuiSvgIcon-root {
      fill: #0282DA;
      width: 22px;
      height: 22px; }
    #full-calendar .calendar-date-picker .MuiInput-underline::after {
      border: none; }
    #full-calendar .calendar-date-picker .MuiInputAdornment-positionEnd {
      margin-left: 0; }
  #full-calendar .event-content {
    height: 100%;
    width: 100%;
    border-radius: 4px; }
  #full-calendar .event-box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 16px; }
  #full-calendar .header-text {
    color: #ffffff;
    font: normal 700 12px/16px Nunito Sans, sans-serif;
    text-transform: capitalize; }
  #full-calendar .content-container {
    padding: 5px 16px;
    height: calc(100% - 22px); }
  #full-calendar .content-text {
    color: #393939;
    font: normal 500 14px/19px Nunito Sans, sans-serif;
    margin-bottom: 0;
    margin-top: 0; }
  #full-calendar .from-widget-box {
    text-align: right; }
    #full-calendar .from-widget-box p {
      font: normal 500 10px/15px Nunito Sans, sans-serif;
      color: #323232;
      margin: 0; }
  #full-calendar .resources-content {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px; }
  #full-calendar .staff-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50px;
    background: #F2F9FD;
    border: 0.5px solid #A2D1F2;
    color: #A2D1F2; }
  #full-calendar .staff-detail {
    text-align: left; }
  #full-calendar .staff-name {
    color: #393939;
    font: normal 500 16px/22px Nunito Sans, sans-serif;
    margin: 0 auto; }
  #full-calendar .staff-profession {
    color: #393939;
    font: normal 400 14px/19px Nunito Sans, sans-serif;
    margin: 0 auto; }
  #full-calendar .loading {
    position: absolute;
    left: 45%;
    top: 45%;
    z-index: 9999; }

.tooltip-content .tooltip-header {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px; }
  .tooltip-content .tooltip-header .header-icon {
    fill: #FFFFFF;
    font-size: 16px; }
  .tooltip-content .tooltip-header .header-text span {
    color: #FFFFFF;
    font: normal 700 14px/21px Nunito Sans, sans-serif; }

.tooltip-content .tooltip-customer-box {
  padding: 10px 12px; }
  .tooltip-content .tooltip-customer-box .customer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px; }
    .tooltip-content .tooltip-customer-box .customer-header .header-title .title {
      color: #A4A4A4;
      font: normal 600 10px/15px Nunito Sans, sans-serif; }
    .tooltip-content .tooltip-customer-box .customer-header .header-status span {
      font: normal 500 14px/21px Nunito Sans, sans-serif;
      text-transform: capitalize; }
  .tooltip-content .tooltip-customer-box .customer-detail {
    padding-bottom: 10px;
    border-bottom: 1px solid #E9E9E9; }
    .tooltip-content .tooltip-customer-box .customer-detail .customer-name {
      color: #393939;
      font: normal 500 14px/21px Nunito Sans, sans-serif;
      margin-top: 0;
      margin-bottom: 0; }
    .tooltip-content .tooltip-customer-box .customer-detail .customer-phone {
      color: #707070;
      font: normal 400 12px/16px Nunito Sans, sans-serif;
      margin-top: 0;
      margin-bottom: 0; }
    .tooltip-content .tooltip-customer-box .customer-detail .customer-email {
      color: #707070;
      font: normal 400 12px/16px Nunito Sans, sans-serif;
      margin-top: 0;
      margin-bottom: 0; }

.tooltip-content .tooltip-service-box {
  padding: 0 12px 10px; }
  .tooltip-content .tooltip-service-box .service-header {
    margin-bottom: 3px; }
    .tooltip-content .tooltip-service-box .service-header .title {
      color: #A4A4A4;
      font: normal 600 10px/15px Nunito Sans, sans-serif; }
  .tooltip-content .tooltip-service-box .service-detail {
    display: flex;
    justify-content: space-between;
    align-items: start; }
    .tooltip-content .tooltip-service-box .service-detail .service-name {
      color: #393939;
      font: normal 500 14px/19px Nunito Sans, sans-serif;
      margin: 0 auto; }
    .tooltip-content .tooltip-service-box .service-detail .service-duration {
      color: #393939;
      font: normal 400 12px/18px Nunito Sans, sans-serif;
      margin: 0 auto; }
    .tooltip-content .tooltip-service-box .service-detail .service-price {
      color: #393939;
      font: normal 500 14px/19px Nunito Sans, sans-serif; }

.tooltip-content .tooltip-notes-box {
  padding: 0 12px 10px;
  border-top: 1px solid #E9E9E9; }
  .tooltip-content .tooltip-notes-box .notes-header {
    margin-bottom: 3px; }
    .tooltip-content .tooltip-notes-box .notes-header .title {
      color: #A4A4A4;
      font: normal 600 10px/15px Nunito Sans, sans-serif; }
  .tooltip-content .tooltip-notes-box .notes-box .notes-text {
    color: #393939;
    font: normal 600 14px/19px Nunito Sans, sans-serif;
    margin: 0 auto; }

.fc-event-resizer .fc-event-resizer-end {
  visibility: hidden; }

.fc-timegrid-col-events .fc-event-resizer-end {
  visibility: hidden; }

.time-off-description {
  font-size: 12px;
  color: #c7c7c7; }

.time-off-title-content .time-off-actions {
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 100%; }

.time-off-title-content .title {
  font-size: 18px;
  font-weight: 700; }

.time-off-title-content .close-icon {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  color: #A4A4A4; }

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

